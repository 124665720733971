import {gql} from "@apollo/client";

export const GET_FILTER_OPTIONS_SIDES = gql(`
query getFilterOptionsSides {
  sides(first: 9999) {
    nodes {
      slug
      name
    }
  }
}
`);
import { gql } from "@apollo/client";

export const GET_LEAGUE_TABLE_PROGRESS = gql`
query getLeagueTableProgress($division: Int!, $season: Int!, $teamId: Int!) {
  leagueTableProgress(division: $division, season: $season, teamId: $teamId) {
    form
    progress {
      round
      position
    }
  }
}
`;
import {useQuery} from "@apollo/client";

export const useGraphqlQuery = (query, options = {}) => {
	const { loading, error, data, ...rest } = useQuery(query, options);

	return {
		loading,
		error,
		data,
		...rest,
	};
};

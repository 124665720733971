import {NavLink} from "react-router-dom";

const Divisions = ({divisions}) => {

	console.log('Divisions', divisions);

	return (
		<div>Plays in: { divisions.map(division => <NavLink key={division.slug} to={division.uri}>{division.name}</NavLink>) }</div>
	);

}

export default Divisions;
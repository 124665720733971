import {memo} from "react";

import Positions from "../filters/Positions";
import Sides from "../filters/Sides";
import Countries from "../filters/Countries";
import Teams from "../filters/Teams";
import PlayingStatuses from "../filters/PlayingStatuses";
import Internationals from "../filters/Internationals";
import Name from "../filters/Name";

const Filters = ({ onChange, onReset = null, defaultValues = null }) => {

	const getDefaultValue = (type) => {
		return defaultValues?.[type] ?? null;
	};

	return (
		<div className="filters">
			<h3>Filters</h3>
			<div style={{display: "flex", flexWrap: "wrap", minWidth: "300px", margin: "0 0 2em"}}>
				<PlayingStatuses
					defaultValue={ getDefaultValue('playingStatus') }
					onChange={(values) => onChange('playingStatus', values)}
				/>
				<Positions
					defaultValue={ getDefaultValue('positions') }
					onChange={(values) => onChange('positions', values)}
				/>
				<Sides
					defaultValue={ getDefaultValue('sides') }
					onChange={(values) => onChange('sides', values)}
				/>
				<Countries
					defaultValue={ getDefaultValue('countries') }
					onChange={(values) => onChange('countries', values)}
				/>
				<Teams
					defaultValue={ getDefaultValue('teams') }
					onChange={(values) => onChange('teams', values)}
				/>
				<Internationals
					defaultValue={ getDefaultValue('internationals') }
					onChange={(values) => onChange('internationals', values)}
				/>
				<div style={{width: "100%", height: "20px"}} />
				<Name
					defaultValue={ defaultValues['search'] ? defaultValues['search'] : null }
					onChange={(name) => onChange('search', name)}
				/>
				<div style={{width: "100%", height: "20px"}} />
				<button onClick={onReset ? onReset : null} style={{appearance: "none", background: "#ccc", border: "none", padding: "1em", cursor:"pointer"}}>Reset Filters</button>
			</div>
		</div>
	);
}

export default memo(Filters, (prevProps, nextProps) => {
	return prevProps.defaultValues === nextProps.defaultValues;
});
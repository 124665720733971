import {useMemo} from 'react';
import {useTeamForeignPlayerCount} from "./useTeamForeignPlayerCount";
import {useTranslate} from "../../context/I18nContext";

export const useTeamWarnings = ({ starting11 = [], substitutes = [] }) => {
	const errors = [];
	const translate = useTranslate();

	const allPlayers = [...(starting11 || []), ...(substitutes || [])];

	// Check if there are 11 players in the starting 11
	if(starting11 && starting11.filter(p => p.player).length !== 11) {
		errors.push(translate('team_warning_player_count'));
	}

	// Check if there are more than 4 foreign players in the starting 11
	const foreignPlayersCount = useTeamForeignPlayerCount({ players: starting11 });
	if(foreignPlayersCount > 5) {
		errors.push(translate('team_warning_foreign_player_count'));
	}

	// Check if we use more than 5 players in D, M or A position in the starting 11
	const positionsCount = useMemo(() => {
		if(!starting11) {
			return {};
		}
		return starting11.reduce((acc, cur) => {
			if(cur.position === 'DM') {
				acc['D'] = (acc['D'] || 0) + 0.5;
				acc['M'] = (acc['M'] || 0) + 0.5;
			}
			else if(cur.position === 'MA') {
				acc['M'] = (acc['M'] || 0) + 0.5;
				acc['A'] = (acc['A'] || 0) + 0.5;
			}
			else {
				acc[cur.position] = (acc[cur.position] || 0) + 1;
			}
			return acc;
		}, {});
	}, [starting11]);

	const dmaCount = Object.values(positionsCount).filter((count) => count > 5).reduce((acc, cur) => acc + cur, 0);
	if(dmaCount > 5) {
		errors.push(translate('team_warning_dma_count'));
	}

	// Check if we have fewer or more than 1 goalkeeper in the starting 11
	const goalkeepersCount = ( starting11 ? starting11.filter((player) => player.position === 'GK').length : 0 );
	if(goalkeepersCount !== 1) {
		errors.push(translate('team_warning_gk_count'));
	}

	// Check if there are more than or fewer than 1 captain in the starting 11
	const captainCount = ( starting11 ? starting11.filter((player) => player?.captain && player?.captain === true).length : 0 );
	if(captainCount !== 1) {
		errors.push(translate('team_warning_captain_count'));
	}

	// Check for duplicate players
	if(allPlayers) {
		const uniquePlayers = new Set();
		allPlayers.forEach((player) => {
			if(player?.player?.id) {
				if(uniquePlayers.has(player.player.id)) {
					errors.push(`${translate('team_warning_duplicate_player')} (${player.player.title})`);
				}
				else {
					uniquePlayers.add(player.player.id);
				}
			}
		});
	}

	// Check if we have injured or suspended players
	if(allPlayers) {
		allPlayers.forEach((player) => {
			if(player?.player?.playerData?.availability?.injured && player.player.playerData.availability.injured === true) {
				errors.push(`${translate('team_warning_injuries')} (${player.player.title})`);
			}
			if(player?.player?.playerData?.availability?.suspended && player.player.playerData.availability.suspended === true) {
				errors.push(`${translate('team_warning_suspensions')} (${player.player.title})`);
			}
		});
	}
	return [...new Set(errors)]; // Return unique
};
import { gql } from '@apollo/client';
import {FIXTURE_DATA_TEAM} from "./FIXTURE_DATA_TEAM";

export const FIXTURE_DATA_FIELDS = gql`
fragment FixtureDataFields on Fixture {
  uri
  slug
  fixtureData {
    round
    roundAlternateName
    season
    awayScore
    awayTeam {
      ...FixtureDataTeam
    }
    homeScore
    homeTeam {
      ...FixtureDataTeam
    }
  }
}
${FIXTURE_DATA_TEAM}
`;
import {memo} from "react";
import {useDateTimeFormat} from "../../context/GeneralSettingsContext";

const Log = ({data, rolling = false}) => {

	const formattedDateTime = useDateTimeFormat(data.timestamp / 1000);
	const secret = data.secret;
	const formattedValue = ( secret || rolling
		? '??'
		: (data.value < 10 ? `0${data.value}` : String(data.value))
	);

	console.log('Log', data.value, data.timestamp);

	return (
		<li><strong>{`${formattedValue}`}</strong> - { formattedDateTime } { secret && 'Secret roll' }</li>
	);
}

export default memo(Log, (prevProps, nextProps) => {
	return prevProps.data.timestamp === nextProps.data.timestamp
		&& prevProps.data.value === nextProps.data.value
		&& prevProps.rolling === nextProps.rolling;
});
import {useTeamInternationalCount} from "../../../../hooks/game/useTeamInternationalCount";

const InternationalCount = ({ players }) => {

	console.log('InternationalCount');
	const internationals = useTeamInternationalCount({ players });

	return (
		<div>Internationals: { internationals.international } U21: { internationals.u21 }</div>
	);
}

export default InternationalCount;
import {memo} from "react";
import {useCalculatedAge} from "../../../hooks/utils/useCalculatedAge";

const Age = ({age}) => {
	const calculatedAge = useCalculatedAge({age: age});

	console.log('Age', age);

	return (
		<div>Age: { age } | Calculated age: { calculatedAge }</div>
	);
}

export default memo(Age, (prevProps, nextProps) => {
	return prevProps.age === nextProps.age;
});
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import App from './App';
import "./temp-styles.css";

const client = new ApolloClient({
	uri: process.env.REACT_APP_GRAPHQL_API_URL,
	cache: new InMemoryCache(),
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
	  <ApolloProvider client={client}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	  </ApolloProvider>
  </React.StrictMode>
);
import {useTeamAverageAge} from "../../../../hooks/game/useTeamAverageAge";
import {useCalculatedAge} from "../../../../hooks/utils/useCalculatedAge";

const AverageAge = ({players, season = null}) => {

	console.log('AverageAge', players);
	const averageAge = useCalculatedAge({
		age: useTeamAverageAge(players),
		season
	});

	return (
		<div>Average Age: { averageAge }</div>
	);
}

export default AverageAge;
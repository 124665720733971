import { useMemo } from 'react';

export const useTeamInternationalCount = ({ players }) => {
	return useMemo(() => {
		const count = {
			international: 0,
			u21: 0
		};

		if(players) {
			players.forEach((player) => {
				if(player?.player?.playerData?.international) {
					count.international += 1;
				}
				if(player?.player?.playerData?.u21) {
					count.u21 += 1;
				}
			});
		}

		return count;
	}, [players]);
};
import {memo} from "react";

import Name from "../filters/Name";
import Divisions from "../filters/Divisions";

const Filters = ({ onChange, onReset = null, defaultValues = null }) => {

	const getDefaultValue = (type) => {
		return defaultValues?.[type] ?? null;
	};

	return (
		<div className="filters">
			<h3>Filters</h3>
			<div style={{display: "flex", flexWrap: "wrap", minWidth: "300px", margin: "0 0 2em"}}>
				<Divisions
					defaultValue={ getDefaultValue('divisions') }
					onChange={(values) => onChange('divisions', values)}
				/>
				<div style={{width: "100%", height: "20px"}} />
				<Name
					defaultValue={ defaultValues['search'] ? defaultValues['search'] : null }
					onChange={(name) => onChange('search', name)}
				/>
				<div style={{width: "100%", height: "20px"}} />
				<button onClick={onReset ? onReset : null} style={{appearance: "none", background: "#ccc", border: "none", padding: "1em", cursor:"pointer"}}>Reset Filters</button>
			</div>
		</div>
	);
}

export default memo(Filters, (prevProps, nextProps) => {
	return prevProps.defaultValues === nextProps.defaultValues;
});
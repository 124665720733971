import {useCallback, useMemo} from "react";
import {useCurrentSeason} from "../context/GameSettingsContext";
import {useGetFixturesByTypes} from "../hooks/useGetFixturesByTypes";
import {useTranslation} from "../context/I18nContext";
import {useLocalStorage} from "../hooks/utils/useLocalStorage";

import Round from "../components/fixtures/Round";
import Filters from "../components/fixtures/Filters";

const FixturesPage = ({ fixtureTypes = null, division = null }) => {

	const noFixtures = useTranslation('fixtures_no_fixtures');
	const currentSeason = useCurrentSeason();
	const initialFilterState = {
		season: [{ key: currentSeason, label: currentSeason }],
	};

	// The values returned from the filters. For <select> it will include { value, label } and plain text string for <input>
	const [selectedValues, setSelectedValues] = useLocalStorage(
		"FixturesSelectedValues",
		initialFilterState
	);

	const handleFiltersChange = useCallback((filter, values) => {

		const previousValues = selectedValues[filter];
		const newValues = Array.isArray(values)
			? values.map((valueObject) => valueObject.value) // Values returned from <Select />
			: values; // Values returned from plain input field

		if(newValues !== previousValues) {
			setSelectedValues((prevSelectedOptions) => ({
				...prevSelectedOptions,
				[filter]: values,
			}));
		}
	}, [selectedValues, setSelectedValues]);

	const { loading, error, data, fixturesByRound } = useGetFixturesByTypes({
		division: ( division ? division : null ),
		fixtureTypes: ( fixtureTypes ? fixtureTypes : null ),
		season: selectedValues.season.value, // We expect only one season
	});

	const renderRounds = useMemo(() => {
		if(!fixturesByRound) return null;

		const fixturesByRoundKeys = Object.keys(fixturesByRound);

		return (
			<div className="rounds" style={{display: "flex", flexDirection:"column-reverse"}}>
				{
					fixturesByRoundKeys.length > 0
						? fixturesByRoundKeys.map(round => {
							return (
								<Round
									key={ round }
									round={ round }
									fixtures={ fixturesByRound[round].fixtures }
									alternateNames={ fixturesByRound[round].alternateNames }
								/>
							)
						})
						:
						<em>{noFixtures}</em>
				}
			</div>
		);
	}, [fixturesByRound, noFixtures]);

	console.log('FixturesPage', data,fixturesByRound, selectedValues, selectedValues.season.value);

	return (
		<div className="page fixtures-page">
			<h1>FIXTURES PAGE</h1>

			<Filters
				onChange={handleFiltersChange}
				defaultValues={selectedValues}
			/>

			{
				loading
					? <p>Loading...</p>
					: ( error ? <p>Error : {error.message}</p> : renderRounds )
			}

			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li>Split fixtures into rounds?</li>
					<li>Allow fixtures to have no team?</li>
				</ul>
			</div>
		</div>
	);
};

export default FixturesPage;
import {memo} from "react";
import {NavLink} from "react-router-dom";

const CurrentTeam = ({data}) => {
	console.log('CurrentTeam', data);

	return (
		<div>Current Team (Last Team): <NavLink to={data.uri}>{ data.title }</NavLink></div>
	);
}

export default memo(CurrentTeam, (prevProps, nextProps) => {
	return prevProps.data.uri === nextProps.data.uri;
});
import {useMatchEventTypeName} from "../../../context/MatchEventTypesContext";

const MatchEvent = ({data, isHomeTeam, runningScore}) => {

	const { minute, matchEventType, teamInvolved, player1Involved, player1InvolvedExtra, player2Involved, player2InvolvedExtra} = { ...data };
	const type = useMatchEventTypeName(matchEventType);

	console.log('MatchEvent', minute, type, isHomeTeam, runningScore, data);

	return (
		<ul className={`match-event ${matchEventType}`}>
			<li>Minute { minute }</li>
			<li>Type: { type }</li>
			<li>Score: { `${runningScore.home} - ${runningScore.away}` }</li>
			{ teamInvolved ? <li>Team involved: {isHomeTeam ? 'Home Team' : 'Away Team'}</li> : null }
			{ player1Involved ? <li>Player 1 involved: {player1Involved[0].title}</li> : null }
			{ player1InvolvedExtra ? <li>Player 1 involved (Extra): {player1InvolvedExtra}</li> : null }
			{ player2Involved ? <li>Player 2 involved: {player2Involved[0].title}</li> : null }
			{ player2InvolvedExtra ? <li>Player 2 involved (Extra): {player2InvolvedExtra}</li> : null }
		</ul>
	);
}

export default MatchEvent;
const DivisionPage = () => {

	return (
		<div className="page division-page">
			<h1>DIVISION PAGE</h1>
			<p style={{color: "tomato"}}>Work in progress</p>
		</div>
	)
}

export default DivisionPage;
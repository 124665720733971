import {memo, useEffect, useState} from "react";
import {useTranslate} from "../../context/I18nContext";
import {useSeasonsAvailable} from "../../context/GameSettingsContext";
import Select from "react-select";

const Seasons = ({ onChange, defaultValue = null, multi = true }) => {

	const translate = useTranslate();
	const seasonsAvailable = useSeasonsAvailable();
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if(Array.isArray(seasonsAvailable)) {
			const seasonsAvailableOptions = seasonsAvailable.map((season) => ({
				label: season,
				value: season,
			}));
			setOptions(seasonsAvailableOptions);
		}
	}, [seasonsAvailable]);

	return (
		<div>
			<label>
				{translate('filter_season_label')}
				<Select
					options={options}
					placeholder={translate('filter_season_placeholder')}
					onChange={onChange}
					defaultValue={defaultValue}
					value={defaultValue}
					isMulti={multi}
					//noOptionsMessage
				/>
			</label>
		</div>
	);
};

export default memo(Seasons, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});
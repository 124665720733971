import { gql } from "@apollo/client";
import { FIXTURE_DATA_FIELDS } from "./fragments/FIXTURE_DATA_FIELDS";

export const GET_FIXTURES_BY_TYPES = gql`
  query getFixturesByTypes($fixtureTypes: [String!]!, $season: String!) {
    fixtures(
      where: {
        orderby: [{ field: ACF_FIXTURE_ROUND, order: ASC }, {field: TITLE, order: ASC}]
        metaQuery: {
          relation: AND
          metaArray: [
            { key: "season", value: $season }
          ]
        }
        taxQuery: {
          taxArray: [
            { taxonomy: FIXTURETYPE, terms: $fixtureTypes }
          ]
          relation: AND
        }
      }
      first: 9999
    ) {
      nodes {
        ...FixtureDataFields
      }
    }
  }
  ${FIXTURE_DATA_FIELDS}
`;
import {memo} from "react";
import {useTranslation} from "../../../context/I18nContext";

const InternationalStatus = ({international = false, u21 = false}) => {

	const internationalLabel = useTranslation('player_data_international');
	const u21Label = useTranslation('player_data_u21');

	console.log('InternationalStatus', international, u21);

	return (
		<div>International Status: { international && internationalLabel } { u21 && u21Label }</div>
	);
}

export default memo(InternationalStatus, (prevProps, nextProps) => {
	return prevProps.international === nextProps.international
		&& prevProps.u21 === nextProps.u21;
});
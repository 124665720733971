import {NavLink} from "react-router-dom";
import {useTranslation} from "../../../context/I18nContext";

const MoM = ({ data }) => {

	console.log('MoM', data);
	const noMoM = useTranslation('match_centre_no_mom');

	return (
		<div className="mom">
			<h4>Man of the Match</h4>
			{ data ? <NavLink to={data.uri}>{ data.title }</NavLink> : <em>{noMoM}</em> }
		</div>
	);
};

export default MoM;
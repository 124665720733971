import {GET_PLAYERS_BY_TEAM} from "../graphql-queries/GET_PLAYERS_BY_TEAM";
import {useGraphqlQuery} from "./useGraphqlQuery";

export const useGetPlayersByTeam = ({teamId}) => {

	const { loading, error, data } = useGraphqlQuery(GET_PLAYERS_BY_TEAM, {
		variables: {
			teamId: String(teamId),
		},
	});

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}
	if(!data?.players?.edges) {
		return { data: null };
	}

	return { data: [ ...data.players.edges ] };
};
import {gql} from "@apollo/client";

export const GET_REFEREES = gql`
query getReferees {
  referees(first: 9999, where: {orderby: {field: TITLE, order: ASC}}) {
    nodes {
      slug
      title
      uri
    }
  }
}
`;
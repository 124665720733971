import {Routes, Route, NavLink, Navigate} from "react-router-dom";

import {GeneralSettingsProvider} from "./context/GeneralSettingsContext";
import {GameSettingsProvider} from "./context/GameSettingsContext";
import {I18nProvider} from "./context/I18nContext";
import {WeatherProvider} from "./context/WeatherContext";
import {CountriesProvider} from "./context/CountriesContext";
import {PlayingStatusesProvider} from "./context/PlayingStatusesContext";
import {MatchEventTypesProvider} from "./context/MatchEventTypesContext";

import {useGetInitialData} from "./hooks/useGetInitialData";

import HomePage from "./pages/HomePage";
import TeamsPage from "./pages/TeamsPage";
import TeamPage from "./pages/TeamPage";
import FixturesPage from "./pages/FixturesPage";
import FixturePage from "./pages/FixturePage";
import LeagueTablesPage from "./pages/LeagueTablesPage";
import PlayersPage from "./pages/PlayersPage";
import PlayerPage from "./pages/PlayerPage";
import RefereesPage from "./pages/RefereesPage";
import RefereePage from "./pages/RefereePage";
import DivisionPage from "./pages/DivisionPage";
import KitPage from "./pages/KitPage";
import {ReadingSettingsProvider} from "./context/ReadingSettingsContext";

function App() {

	const { loading, error, data } = useGetInitialData();

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error: {error.message}</p>;

	// Context
	const {
		generalSettings,
		readingSettings,
		acfOptionsGameSettings,
		acfOptionsTranslations,
		weather,
		matchEventTypes,
		countries,
		playingStatuses,
	} = { ...data };

	const gameSettings = acfOptionsGameSettings.gameSettings;
	const i18n = acfOptionsTranslations.i18n.translationStrings.reduce(
		(acc, { key, value }) => ({ ...acc, [key]: value }),
		{}
	);

	console.log('APP',process.env.NODE_ENV, data);

	return (
		<GeneralSettingsProvider value={generalSettings}>
			<ReadingSettingsProvider value={readingSettings}>
				<GameSettingsProvider value={gameSettings}>
					<I18nProvider value={i18n}>
						<CountriesProvider value={countries}>
							<WeatherProvider value={weather}>
								<MatchEventTypesProvider value={matchEventTypes}>
									<PlayingStatusesProvider value={playingStatuses}>

										<div id="smrpg-app">
											<nav>
												MENU
												<ul>
													<li><NavLink to="/">HOMEPAGE</NavLink></li>
													<li><NavLink to="/teams">TEAMS</NavLink></li>
													<li>
														<NavLink to="/fixtures">FIXTURES</NavLink>
														<ul>
															<li><NavLink to="/fixtures/nsl">NSL</NavLink></li>
															<li><NavLink to="/fixtures/nsa">NSA</NavLink></li>
															<li><NavLink to="/fixtures/us-cup">US CUP</NavLink></li>
														</ul>
													</li>
													<li>
														<NavLink to="/league-tables">LEAGUE TABLES</NavLink>
														<ul>
															<li><NavLink to="/league-tables/nsl">NSL</NavLink></li>
															<li><NavLink to="/league-tables/nsa">NSA</NavLink></li>
														</ul>
													</li>
													<li><NavLink to="/players">PLAYERS</NavLink></li>
													<li><NavLink to="/referees">REFEREES</NavLink></li>
												</ul>
											</nav>
											<Routes>
												<Route exact path="/" element={<HomePage />} />

												<Route path="/teams/" element={<TeamsPage />} />
												<Route path="/teams/:teamId" element={<TeamPage />} />

												<Route path="/players/" element={<PlayersPage />} />
												<Route path="/players/:playerId" element={<PlayerPage />} />

												<Route path="/fixtures/" element={<Navigate to="/fixtures/nsl" />} />
												<Route path="/fixtures/nsl/" element={<FixturesPage fixtureTypes={[16,19]} division={2} />} />
												<Route path="/fixtures/nsa/" element={<FixturesPage fixtureTypes={[16,19]} division={3} />} />
												<Route path="/fixtures/us-cup/" element={<FixturesPage fixtureTypes={[17]} />} />
												<Route path="/fixtures/fixture/:fixtureId" element={<FixturePage />} />

												<Route path="/league-tables/" element={<Navigate to="/league-tables/nsl" />} />
												<Route path="/league-tables/nsl/" element={<LeagueTablesPage division={2} />} />
												<Route path="/league-tables/nsa/" element={<LeagueTablesPage division={3} />} />

												<Route path="/referees/" element={<RefereesPage />} />
												<Route path="/referees/:refereeId" element={<RefereePage />} />

												<Route path="/division/:divisionId" element={<DivisionPage />} />
												<Route path="/kits/:kitId" element={<KitPage />} />
											</Routes>
										</div>

									</PlayingStatusesProvider>
								</MatchEventTypesProvider>
							</WeatherProvider>
						</CountriesProvider>
					</I18nProvider>
				</GameSettingsProvider>
			</ReadingSettingsProvider>
		</GeneralSettingsProvider>
	);
}

export default App;
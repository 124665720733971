import {useTeamForeignPlayerCount} from "../../../../hooks/game/useTeamForeignPlayerCount";

const ForeignPlayerCount = ({ players }) => {

	console.log('ForeignPlayerCount');
	const count = useTeamForeignPlayerCount({ players });

	return (
		<div>Foreign players: { count }</div>
	);
}

export default ForeignPlayerCount;
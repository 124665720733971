import Fixture from "./Fixture";
import {useTranslation} from "../../context/I18nContext";

const Round = ({ round, fixtures = [], alternateNames=[] }) => {

	const roundLabel = useTranslation('fixture_round');
	console.log('Round', round, fixtures, alternateNames);

	return (
		<div>
			<h4>{ alternateNames.length > 0 ? alternateNames.join(' | ') : `${roundLabel} ${round}`}</h4>
			{
				fixtures.map(fixture => {
					return (
						<Fixture
							key={fixture.slug}
							data={ fixture }
						/>
					)
				})
			}
		</div>
	);
};

export default Round;
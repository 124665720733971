import {createContext, useContext, useMemo} from 'react';

export const WeatherContext = createContext({});

export const WeatherProvider = ({ value, children }) => {
	return (
		<WeatherContext.Provider value={value}>
			{children}
		</WeatherContext.Provider>
	);
};

export function useWeatherName(key) {
	const weatherTypes = useContext(WeatherContext);
	const weatherTypeMap = useMemo(() => {
		return weatherTypes.reduce((map, weather) => {
			map[weather.key] = weather.value;
			return map;
		}, {});
	}, [weatherTypes]);

	return weatherTypeMap[key] || key;
}

export function useWeatherTypes() {
	const weatherTypes = useContext(WeatherContext);
	const weatherTypesMap = useMemo(() => {
		return weatherTypes.reduce((map, weather) => {
			map[weather.key] = weather.value;
			return map;
		}, {});
	}, [weatherTypes]);

	return (key) => {
		return weatherTypesMap[key] || key;
	};
}
import {createContext, useContext, useMemo} from 'react';

export const MatchEventTypesContext = createContext({});

export const MatchEventTypesProvider = ({ value, children }) => {
	return (
		<MatchEventTypesContext.Provider value={value}>
			{children}
		</MatchEventTypesContext.Provider>
	);
};

export function useMatchEventTypeName(key) {
	const matchEventTypes = useContext(MatchEventTypesContext);
	const matchEventTypeMap = useMemo(() => {
		return matchEventTypes.reduce((map, matchEventType) => {
			map[matchEventType.key] = matchEventType.value;
			return map;
		}, {});
	}, [matchEventTypes]);

	return matchEventTypeMap[key] || key;
}

export function useMatchEventTypes() {
	const matchEventTypes = useContext(MatchEventTypesContext);
	const matchEventTypeMap = useMemo(() => {
		return matchEventTypes.reduce((map, matchEventType) => {
			map[matchEventType.key] = matchEventType.value;
			return map;
		}, {});
	}, [matchEventTypes]);

	return (key) => {
		return matchEventTypeMap[key] || key;
	};
}
import {gql} from "@apollo/client";

export const GET_REFEREE_BY_URI = gql`
query getRefereeByUri($uri: String!) {
  nodeByUri(uri: $uri) {
    __typename
    ... on Referee {
      slug
      title
    }
  }
}
`;
import {memo} from "react";
import {usePlayingStatusName} from "../../../context/PlayingStatusesContext";

const PlayingStatus = ({status}) => {
	const playingStatusName = usePlayingStatusName(status);

	console.log('PlayingStatus', playingStatusName);

	return (
		<div>Playing Status: { playingStatusName }</div>
	);
}

export default memo(PlayingStatus, (prevProps, nextProps) => {
	return prevProps.status === nextProps.status;
});
const BaseTactics = ({data}) => {

	console.log('BaseTactics', data);

	return (
		<div>Base Tactics: { data.formation} (Counter Attack: { data.counterAttack})</div>
	);

}

export default BaseTactics;
import {memo, useEffect, useState} from "react";
import {useGetFilterOptionsPositions} from "../../hooks/useGetFilterOptionsPositions";
import {useTranslate} from "../../context/I18nContext";

import Select from 'react-select';

const Positions = ({ onChange, defaultValue = null, multi = true }) => {
	const translate = useTranslate();
	const { getFilterOptions, data } = useGetFilterOptionsPositions();

	const [options, setOptions] = useState([]);

	const handleMenuOpen = () => {
		if(!data) {
			getFilterOptions();
		}
	};

	useEffect(() => {
		if(data) {
			const positions = data.map((position) => ({
				label: position.name,
				value: position.slug,
			}));
			setOptions(positions);
		}
	}, [data]);

	return (
		<div className="positions-filter">
			<label>
				{translate('filter_positions_label')}
				<Select
					options={options}
					isMulti={multi}
					placeholder={translate('filter_positions_placeholder')}
					onMenuOpen={handleMenuOpen}
					onChange={onChange}
					defaultValue={defaultValue}
					value={defaultValue}
				/>
			</label>
		</div>
	);
};

export default memo(Positions, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});
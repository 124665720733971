import {useMemo} from "react";
import React, {createContext, useContext} from 'react';

export const GameSettingsContext = createContext({});

export const GameSettingsProvider = ({ value, children }) => {
	return (
		<GameSettingsContext.Provider value={value}>
			{children}
		</GameSettingsContext.Provider>
	);
};

export function useCurrentSeason() {
	return useGameSetting('currentSeason');
}
export function useSeasonsAvailable() {
	const seasonsAvailable = useGameSetting('seasonsAvailable');
	return useMemo(() => seasonsAvailable.split(',').map(Number), [seasonsAvailable]);
}

export function useGameSetting(key) {
	const gameSettings = useContext(GameSettingsContext);
	return gameSettings[key] || key;
}
export function useGameSettings() {
	const gameSettings = useContext(GameSettingsContext);
	return (key) => gameSettings[key] || key;
}
import {useTranslation} from "../../../context/I18nContext";
import {useWeatherName} from "../../../context/WeatherContext";

const Weather = ({ name }) => {

	console.log('Weather', name);

	const weatherName = useWeatherName(name);
	const noWeather = useTranslation('match_centre_no_weather');

	return (
		<div className="weather">
			<h4>Weather</h4>
			{ weatherName ? weatherName : <em>{noWeather}</em> }
		</div>
	);

};

export default Weather;
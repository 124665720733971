import {useCountries} from "../../../context/CountriesContext";

const Nationality = ({nationality, showFlag = true}) => {

	const countries = useCountries();

	console.log('Nationality', nationality, showFlag);

	return (
		<>
			{
				nationality.map((countryCode) => {
					const countryName = countries(countryCode);

					if(showFlag) {
						return (
							<figure key={countryCode} className={`flag flag-${countryCode}`}>
								<img
									src={`/assets/images/flags/4x3/${ countryCode.toLowerCase() }.svg`}
									alt={ countryName }
									loading="lazy"
									width={40}
									height={30}
								/>
							</figure>
						);
					}
					return <span key={countryCode} className="country-name">{ countryName }</span>;
				})
			}
		</>
	);
}

export default Nationality;
import {useGetPlayerByUri} from "../hooks/useGetPlayerByUri";

import Age from "../components/player/player-data/Age";
import PlayingStatus from "../components/player/player-data/PlayingStatus";
import CurrentTeam from "../components/player/player-data/CurrentTeam";
import Nationality from "../components/player/player-data/Nationality";
import InternationalStatus from "../components/player/player-data/InternationalStatus";
import PreferredFoot from "../components/player/player-data/PreferredFoot";
import Positions from "../components/player/player-data/Positions";
import Sides from "../components/player/player-data/Sides";
import Stats from "../components/player/player-data/stats/Stats";
import Availability from "../components/player/player-data/availability/Availability";

const PlayerPage = () => {

	const { loading, error, data } = useGetPlayerByUri();

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No Player</p>

	const { title, playerData } = { ...data };
	const { shirtNumber, age, playingStatus, currentTeam, nationality, international, u21, preferredFoot, positions, sides, stats, availability} = { ...playerData };

	console.log('PlayerPage', title);

	return (
		<div className="page team-page">
			<h1>PLAYER PAGE</h1>
			<h2>#{shirtNumber} { title}</h2>

			<h3>Misc. data</h3>
			<Age age={age} />
			<PlayingStatus status={playingStatus} />
			<CurrentTeam data={currentTeam} />
			<Nationality nationality={nationality} />
			<InternationalStatus international={ international } u21={ u21 } />
			<PreferredFoot preferredFoot={ preferredFoot } />

			<h3>Positions</h3>
			<Positions
				positions={positions}
				abbreviationOnly={false}
			/>

			<h3>Sides</h3>
			<Sides
				sides={sides}
				abbreviationOnly={true}
			/>

			<h3>Stats</h3>
			<Stats
				stats={stats}
				displayStats={['tech','tac','phys','overall']}
			/>

			<h3>Morale/Fitness</h3>
			<Stats
				stats={stats}
				displayStats={['morale','fitness']}
			/>

			<h3>Availability</h3>
			<Availability
				availability={availability}
			/>

			<div style={{ color: "tomato" }}>
				<h3>TODO</h3>
				<ul>
					<li>Hide NPC stats. Avoid creating (or looking at) NPC players for now...</li>
					<li>Former clubs</li>
					<li>Contract</li>
					<li>Loans</li>
				</ul>
			</div>
		</div>
	);
};

export default PlayerPage;
import {useGraphqlQuery} from "./useGraphqlQuery";
import {useLocation} from "react-router-dom";
import {GET_KIT_BY_URI} from "../graphql-queries/GET_KIT_BY_URI";

export const useGetKitByUri = (uri = null) => {
	const { pathname } = useLocation();
	const kitUri = uri ? uri : pathname;

	const { loading, error, data } = useGraphqlQuery(GET_KIT_BY_URI, {
		variables: {
			uri: kitUri,
		},
	});

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}

	if(!data?.nodeByUri) {
		return { data: null };
	}

	return { data: { ...data.nodeByUri } };
};
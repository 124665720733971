import {useGraphqlQuery} from "./useGraphqlQuery";
import {GET_TEAMS} from "../graphql-queries/GET_TEAMS";

export const useGetTeams = ({
	perPage = 10,
	divisions = null,
	search = null,
}) => {
	const { loading, error, data, fetchMore } = useGraphqlQuery(GET_TEAMS, {
		variables: {
			perPage,
			after: null,
			divisions,
			search: (search && typeof search !== 'string' ? null : search ),
		},
	});

	const fetchNextBatch = () => {
		if(!data.teams.pageInfo.hasNextPage) {
			return Promise.resolve();
		}

		return fetchMore({
			variables: { after: data.teams.pageInfo.endCursor },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newNodes = fetchMoreResult.teams.nodes;
				return {
					teams: {
						pageInfo: fetchMoreResult.teams.pageInfo,
						nodes: [...previousResult.teams.nodes, ...newNodes],
						__typename: previousResult.teams.__typename,
					},
				};
			},
		});
	};

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}
	if(!data?.teams?.nodes || !data?.teams?.pageInfo) {
		return { teams: null, pageInfo: null };
	}

	return {
		teams: [...data.teams.nodes],
		pageInfo: data.teams.pageInfo,
		fetchNextBatch,
	};
};

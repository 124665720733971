import { gql } from "@apollo/client";
import { FIXTURE_DATA_FIELDS } from "./fragments/FIXTURE_DATA_FIELDS";

export const GET_FIXTURES_BY_TEAM = gql`
  query getFixturesByTeam($teamId: String!, $season: String!) {
    homeFixtures: fixtures(
      where: {
        orderby: { field: ACF_FIXTURE_ROUND, order: ASC }
        metaQuery: {
          relation: AND
          metaArray: [
            { key: "season", value: $season }
            { key: "home_team", value: $teamId }
          ]
        }
      }
      first: 9999
    ) {
      nodes {
        ...FixtureDataFields
        fixtureTypes {
		  nodes {
		    databaseId
		    name
		    slug
		  }
	    }
      }
    }

    awayFixtures: fixtures(
      where: {
        orderby: { field: ACF_FIXTURE_ROUND, order: ASC }
        metaQuery: {
          relation: AND
          metaArray: [
            { key: "season", value: $season }
            { key: "away_team", value: $teamId }
          ]
        }
      }
      first: 9999
    ) {
      nodes {
        ...FixtureDataFields
        fixtureTypes {
		  nodes {
		    databaseId
		    name
		    slug
		  }
	    }
      }
    }
  }
  ${FIXTURE_DATA_FIELDS}
`;

import {useCurrentSeason} from "../context/GameSettingsContext";
import TeamDice from "../components/dice/TeamDice";

const HomePage = () => {

	const currentSeason = useCurrentSeason();

	return (
		<div className="page home-page">
			<h1>HOME PAGE</h1>
			<div>Current season: {currentSeason}</div>

			<h2>Test Dice</h2>
			<TeamDice teamId={null} />

			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li>Delete dice rolls</li>
					<li>Delay, dice rolls</li>
				</ul>
			</div>
		</div>
	)
}

export default HomePage;
import {memo} from "react";
import {useTranslation} from "../../context/I18nContext";
import {useDateTimeFormat} from "../../context/GeneralSettingsContext";

const TableFooter = ({lastUpdated}) => {

	console.log('TableFooter');
	const label = useTranslation('league_table_last_updated');
	const formattedDateTime = useDateTimeFormat(lastUpdated);

	const style = {padding: ".25em", textAlign: 'left'};

	return (
		<tfoot>
		<tr>
			<td colSpan={11} style={style}>{label} {formattedDateTime}</td>
		</tr>
		</tfoot>
	);

}

export default memo(TableFooter, (prevProps, nextProps) => {
	return prevProps.lastUpdated === nextProps.lastUpdated;
});
import { useCallback, useMemo } from "react";
import { useGetPlayers } from "../hooks/useGetPlayers";
import {useLocalStorage} from "../hooks/utils/useLocalStorage";
import {usePostsPerPage} from "../context/ReadingSettingsContext";

import Player from "../components/players/Player";
import Filters from "../components/players/Filters";

const initialFilterState = {
	playingStatus: [],
	positions: [],
	sides: [],
	countries: [],
	teams: [],
	internationals: [],
	search: null,
};

const PlayersPage = () => {
	const perPage = usePostsPerPage();

	// The actual filter values we use in our "player search"
	const [searchValues, setSearchValues] = useLocalStorage(
		"PlayersSearchValues",
		initialFilterState
	);

	// The values returned from the filters. For <select> it will include { value, label } and plain text string for <input>
	const [selectedValues, setSelectedValues] = useLocalStorage(
		"PlayersSelectedValues",
		initialFilterState
	);

	const { loading, error, players, pageInfo, fetchNextBatch } = useGetPlayers({
		perPage,
		...searchValues,
	});

	const memoizedPlayers = useMemo(() => {
		if(!players) return null;
		return players.map((player) => (
			<Player
				key={player.slug}
				data={player}
			/>
		));
	}, [players]);

	const handleFiltersChange = useCallback((filter, values) => {

		const previousValues = searchValues[filter];
		const newValues = Array.isArray(values)
			? values.map((valueObject) => valueObject.value) // Values returned from <Select />
			: values; // Values returned from plain input field

		if(newValues !== previousValues) {
			setSearchValues((prevFilterValues) => ({
				...prevFilterValues,
				[filter]: newValues,
			}));

			setSelectedValues((prevSelectedOptions) => ({
				...prevSelectedOptions,
				[filter]: values,
			}));
		}
	}, [searchValues, setSearchValues, setSelectedValues]);


	const handleResetFilters = () => {
		setSearchValues(initialFilterState);
		setSelectedValues(initialFilterState);
	};

	if(error) return <p>Error : {error.message}</p>;

	const remainingPlayers = Math.max(0, pageInfo?.total - perPage);
	const totalPlayers = players?.length + remainingPlayers;

	console.log('PlayersPage', players);

	return (
		<div className="page players-page">
			<h1>PLAYERS PAGE</h1>

			<Filters
				onChange={handleFiltersChange}
				onReset={handleResetFilters}
				defaultValues={selectedValues}
			/>

			<div>
				Total Number of Players matching the filters:{" "}
				{!isNaN(totalPlayers) ? totalPlayers : null}
			</div>
			<div style={{marginBottom: "1em"}}>Players remaining: {!isNaN(remainingPlayers) ? remainingPlayers : null}</div>

			{loading ? (
				<p>Loading...</p>
			) : totalPlayers > 0 ? (
				<>
					<ul>{memoizedPlayers}</ul>
					{pageInfo?.hasNextPage && (
						<button onClick={() => fetchNextBatch()} style={{marginTop: "1em"}}>
							{loading ? "Loading..." : "Load More"}
						</button>
					)}
				</>
			) : (
				<p>No players matching the filters</p>
			)}

			<div style={{ color: "tomato" }}>
				<h3>TODO</h3>
				<ul>
					<li>More info to list of players.</li>
					<li>Autocomplete player names.</li>
				</ul>
			</div>
		</div>
	);
};

export default PlayersPage;

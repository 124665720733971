import { useCallback, useMemo } from 'react';

export const useTeamPositionAverage = ({players, position, modifier = 0}) => {

	const getOverall = useCallback((player) => {
		const stats = player?.player?.playerData?.stats;
		if(stats) {
			const baseOverall = stats.overall;
			const fitnessMultiplier = (stats.fitness ?? 0) / 100;
			const moraleMultiplier = Math.floor((stats.morale ?? 0) / 10);
			const overall = Math.floor(baseOverall * fitnessMultiplier) + moraleMultiplier;
			return overall > 0 ? overall : 0;
		}
		return 0;
	}, []);

	const defensiveMidfielders = useMemo(() => {
		return players?.filter(player => player.position === 'DM') || [];
	}, [players]);

	const attackingMidfielders = useMemo(() => {
		return players?.filter(player => player.position === 'MA') || [];
	}, [players]);

	return useMemo(() => {
		// Return 0 if players is null or empty
		if (!players || players.length === 0) {
			return 0;
		}

		// Get the players matching the requested position
		let filteredPlayers = players.filter(player => player.position === position);

		// Get the "total score" of the players matching the position
		let totalScore = filteredPlayers.reduce((acc, cur) => {
			return acc + getOverall(cur);
		}, 0);

		// Add "half-position players" to the total score, depending on the requested position
		if(position === 'D') {
			totalScore += defensiveMidfielders.reduce((acc, cur) => {
				return acc + getOverall(cur);
			}, 0) / 2;
		}
		else if(position === 'M') {
			totalScore += defensiveMidfielders.reduce((acc, cur) => {
				return acc + getOverall(cur);
			}, 0) / 2;
			totalScore += attackingMidfielders.reduce((acc, cur) => {
				return acc + getOverall(cur);
			}, 0) / 2;
		}
		else if(position === 'A') {
			totalScore += attackingMidfielders.reduce((acc, cur) => {
				return acc + getOverall(cur);
			}, 0) / 2;
		}

		return Math.floor(totalScore / (position === 'GK' ? 1 : (10 / 3))) + modifier;
	}, [players, position, getOverall, defensiveMidfielders, attackingMidfielders, modifier]);
};
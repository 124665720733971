import {createContext, useContext, useMemo} from 'react';

export const CountriesContext = createContext({});

export const CountriesProvider = ({ value, children }) => {
	return (
		<CountriesContext.Provider value={value}>
			{children}
		</CountriesContext.Provider>
	);
};

export function useCountryName(key) {
	const countries = useContext(CountriesContext);
	const countryMap = useMemo(() => {
		return countries.reduce((map, country) => {
			map[country.key] = country.value;
			return map;
		}, {});
	}, [countries]);

	return countryMap[key] || key;
}

export function useCountries() {
	const countries = useContext(CountriesContext);
	const countryMap = useMemo(() => {
		return countries.reduce((map, country) => {
			map[country.key] = country.value;
			return map;
		}, {});
	}, [countries]);

	return (key) => {
		return countryMap[key] || key;
	};
}
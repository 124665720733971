import {memo} from "react";

const TableHeader = () => {

	console.log('TableHeader');

	const style = {padding: ".25em"};

	return (
		<thead>
		<tr>
			<th style={style}>#</th>
			<th style={{textAlign: 'left'}}>Team</th>
			<th style={style}>Pl</th>
			<th style={style}>W</th>
			<th style={style}>D</th>
			<th style={style}>L</th>
			<th style={style}>F</th>
			<th style={style}>A</th>
			<th style={style}>+/-</th>
			<th style={style}>Pts</th>
			<th style={{textAlign: 'left'}}>Form</th>
		</tr>
		</thead>
	);
}

export default memo(TableHeader, () => true);
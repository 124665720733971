import { useCallback, useMemo } from 'react';

export const useTeamStatAverage = ({ players, stat, modifier = 0 }) => {
	const getStat = useCallback((player) => {
		const stats = player?.player?.playerData?.stats;
		if (stats) {
			const baseValue = stats[stat];
			const fitnessMultiplier = (stats.fitness ?? 0) / 100;
			const moraleMultiplier = Math.floor((stats.morale ?? 0) / 10);
			const value = baseValue * fitnessMultiplier + moraleMultiplier;
			return value > 0 ? value : 0;
		}
		return 0;
	}, [stat]);

	return useMemo(() => {
		if (!players) return 0;
		const totalScore = players.reduce((acc, cur) => {
			return acc + getStat(cur);
		}, 0);
		return Math.floor(totalScore / 11) + modifier;
	}, [players, getStat, modifier]);
};
import { useMemo } from 'react';

export const useTeamForeignPlayerCount = ({ players }) => {
	return useMemo(() => {
		if (!players) {
			return 0;
		}

		const nonForeignCountries = ['US', 'CA', 'MX'];
		const foreignPlayers = players.filter(player => {
			const nationalities = player?.player?.playerData?.nationality;
			if (nationalities) {
				return !nationalities.some(n => nonForeignCountries.includes(n));
			}
			return false;
		});
		return foreignPlayers.length;
	}, [players]);
};
import LeagueTable from "../components/league-table/LeagueTable";

const LeagueTablesPage = ({ division }) => {
	return (
		<div className="page team-page">
			<h1>LEAGUE TABLES PAGE</h1>
			<LeagueTable division={ division } />

			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li></li>
				</ul>
			</div>
		</div>
	);
};

export default LeagueTablesPage;
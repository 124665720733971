import { gql } from "@apollo/client";

export const GET_LEAGUE_TABLE_BY_DIVISION = gql`
query getLeagueTableBySeason($season: Int!, $division: Int!) {
  leagueTable(season: $season, division: $division) {
    teamId
    team {
      databaseId
      slug
      title
      uri
    }
    position
    played
    won
    drawn
    lost
    for
    against
    difference
    points
    form
    lastUpdated
  }
}
`;

import React, {createContext, useContext, useMemo} from 'react';

export const ReadingSettingsContext = createContext({});

export const ReadingSettingsProvider = ({ value, children }) => {
	return (
		<ReadingSettingsContext.Provider value={value}>
			{children}
		</ReadingSettingsContext.Provider>
	);
};

export function usePostsPerPage() {
	const postsPerPage = useReadingSetting('postsPerPage');
	return useMemo(() => postsPerPage, [postsPerPage]);
}

export function useReadingSetting(key) {
	const readingSettings = useContext(ReadingSettingsContext);
	return readingSettings[key] || key;
}
export function useReadingSettings() {
	const readingSettings = useContext(ReadingSettingsContext);
	return (key) => readingSettings[key] || key;
}
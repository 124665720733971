import { memo, useState, useEffect } from "react";
import { useTranslate } from "../../context/I18nContext";

const Name = ({ onChange, defaultValue = null }) => {
	const translate = useTranslate();
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			onChange && onChange(value === '' ? null : value);
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [value, onChange]);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	return (
		<div className="name-filter">
			<label>
				{translate("filter_name_label")}
				<input
					type="search"
					placeholder={translate("filter_name_placeholder")}
					value={value ?? ''}
					onChange={handleChange}
				/>
			</label>
		</div>
	);
};

export default memo(Name, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});
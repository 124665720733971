import {memo} from "react";
import {useTranslation} from "../../../../context/I18nContext";
import Stat from "./Stat";

const Fitness = ({ value }) => {
	console.log('Fitness', value);
	const label = useTranslation('fitness');

	const getThresholdClass = () => {
		if(value < 85) {
			return "fitness-level-4";
		}
		else if(value < 90) {
			return "fitness-level-3";
		}
		else if (value < 95) {
			return "fitness-level-2";
		}
		return "fitness-level-1";
	};

	return (
		<Stat
			value={ value }
			label={ label }
			suffix="%"
			className={ getThresholdClass() }
		/>
	);
}

export default memo(Fitness, (prevProps, nextProps) => {
	return prevProps.value === nextProps.value;
});
import {memo} from "react";
import {NavLink} from "react-router-dom";

const Team = ({ data = {}}) => {

	const { slug, uri, title, divisions } = { ...data };

	console.log('Team', title);

	return (
		<li className="team" id={`team-${slug}`}>
			<NavLink to={ uri }>{ title }</NavLink>
			{
				divisions && divisions?.nodes && divisions.nodes.length > 0
					? divisions.nodes.map(division => {
						return (
							<span key={division.uri}>&nbsp;({division.name})</span>
						);
					})
					: null
			}
		</li>
	);

}

export default memo(Team, (prevProps, nextProps) => {
	return prevProps.data.uri === nextProps.data.uri;
});
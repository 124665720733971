import {NavLink} from "react-router-dom";
import Stats from "../../player/player-data/stats/Stats";
import Nationality from "../../player/player-data/Nationality";
import Availability from "../../player/player-data/availability/Availability";

const Player = ({ data }) => {

	const { player, position, captain } = { ...data };
	const { title, uri, playerData } = { ...player };
	const { shirtNumber, stats, nationality, availability } = { ...playerData };

	console.log('Player', title, stats, availability);

	return (
		<div style={{display: "flex"}}>
			<div style={{display: "flex",width: "35%"}} className="fixture-player-profile">
				#{ shirtNumber }&nbsp;
				<NavLink to={uri}>{ title }</NavLink>
				{ captain && <span className="captain">&nbsp;(C)</span> }
				<Availability
					availability={ availability }
					iconsOnly={true}
				/>
			</div>
			<div style={{width: "10%", textAlign: "center"}}>
				{ position }
			</div>
			<div style={{width: "20%"}} className="fixture-player-flag">
				<Nationality
					nationality={ nationality }
				/>
			</div>
			<Stats
				stats={stats}
				displayStats={['tech']}
				includeBaseStats={ false }
				includeLabels={ true }
			/>
		</div>
	);
}

export default Player;
import {useGetKitByUri} from "../hooks/useGetKitByUri";

const KitPage = () => {

	const { loading, error, data } = useGetKitByUri();

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No Kit</p>
	console.log('KitPage', data);

	const { kitData } = { ...data};

	return (
		<div className="page kit-page">
			<h1>KIT PAGE</h1>
			<div>Design: { kitData.design }</div>
			<div>Shirt1: { kitData.shirt1 }</div>
			<div>Shirt2: { kitData.shirt2 }</div>
			<div>Shirt3: { kitData.shirt3 }</div>
			<div>Shorts1: { kitData.shorts1 }</div>
			<div>Shorts2: { kitData.shorts2 }</div>
			<div>Goalkeeper1: { kitData.goalkeeper1 }</div>
			<div>Dice1: { kitData.dice1 }</div>
			<div>Dice2: { kitData.dice2 }</div>
		</div>
	);
};

export default KitPage;
import {memo, useEffect, useState} from "react";
import {useGetFilterOptionsTeams} from "../../hooks/useGetFilterOptionsTeams";
import {useTranslate} from "../../context/I18nContext";
import Select from 'react-select';

const Teams = ({ onChange, defaultValue = null, multi = true }) => {
	const translate = useTranslate();
	const { getFilterOptions, data } = useGetFilterOptionsTeams();
	const [options, setOptions] = useState([]);

	const handleMenuOpen = () => {
		if(!data) {
			getFilterOptions();
		}
	};

	useEffect(() => {
		if(data) {
			const teams = data.map((team) => ({
				label: team.title,
				value: team.databaseId,
			}));
			setOptions(teams);
		}
	}, [data]);

	return (
		<div className="teams-filter">
			<label>
				{translate('filter_teams_label')}
				<Select
					options={options}
					isMulti={multi}
					placeholder={translate('filter_teams_placeholder')}
					onMenuOpen={handleMenuOpen}
					onChange={onChange}
					defaultValue={defaultValue}
					value={defaultValue}
				/>
			</label>
		</div>
	);
};

export default memo(Teams, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});

import {memo, useEffect, useState} from "react";
import {useGetFilterOptionsSides} from "../../hooks/useGetFilterOptionsSides";
import {useTranslate} from "../../context/I18nContext";

import Select from 'react-select';

const Sides = ({ onChange, defaultValue = null, multi = true }) => {
	const translate = useTranslate();
	const { getFilterOptions, data } = useGetFilterOptionsSides()

	const [options, setOptions] = useState([]);

	const handleMenuOpen = () => {
		if(!data) {
			getFilterOptions();
		}
	};

	useEffect(() => {
		if(data) {
			const sides = data.map((side) => ({
				label: side.name,
				value: side.slug,
			}));
			setOptions(sides);
		}
	}, [data]);

	return (
		<div className="sides-filter">
			<label>
				{translate('filter_sides_label')}
				<Select
					options={options}
					isMulti={multi}
					placeholder={translate('filter_sides_placeholder')}
					onMenuOpen={handleMenuOpen}
					onChange={onChange}
					defaultValue={defaultValue}
					value={defaultValue}
				/>
			</label>
		</div>
	);
};

export default memo(Sides, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});
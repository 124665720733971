import {formatNumber} from "../../helpers";

const Stadium = ({data}) => {

	console.log('Stadium', data);

	return (
		<div>Stadium: { data.name} (Capacity: { formatNumber(data.capacity)})</div>
	);

}

export default Stadium;
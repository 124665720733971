import { GET_FILTER_OPTIONS_COUNTRIES} from "../graphql-queries/GET_FILTER_OPTIONS_COUNTRIES";
import {useGraphqlLazyQuery} from "./useGraphqlLazyQuery";

export const useGetFilterOptionsCountries = () => {

	const { executeQuery, loading, data, error } = useGraphqlLazyQuery(GET_FILTER_OPTIONS_COUNTRIES);

	const getFilterOptions = () => {
		executeQuery().then(r => {});
	};

	if(loading) {
		return { getFilterOptions, loading };
	}
	if(error) {
		return { getFilterOptions, error };
	}
	if(!executeQuery || !data?.countriesInUseForPlayers) {
		return { getFilterOptions, data: null };
	}

	return { getFilterOptions, data: data.countriesInUseForPlayers };
};
import {gql} from "@apollo/client";
import {KIT_DATA} from "./fragments/KIT_DATA";

export const GET_KIT_BY_URI = gql`
query getKitByUri($uri: String!) {
  nodeByUri(uri: $uri) {
    __typename
    ...KitData
  }
}
${KIT_DATA}
`;
import {useGraphqlQuery} from "./useGraphqlQuery";
import {GET_PLAYERS} from "../graphql-queries/GET_PLAYERS";

export const useGetPlayers = ({
	perPage = 10,
	playingStatus = null,
	countries = null,
	internationals = null,
	teams = null,
	sides = null,
	positions = null,
	search = null,
}) => {
	const { loading, error, data, fetchMore } = useGraphqlQuery(GET_PLAYERS, {
		variables: {
			perPage,
			after: null,
			playingStatus,
			countries,
			internationals,
			teams,
			sides,
			positions,
			search: (search && typeof search !== 'string' ? null : search ),
		},
	});

	const fetchNextBatch = () => {
		if(!data.players.pageInfo.hasNextPage) {
			return Promise.resolve();
		}

		return fetchMore({
			variables: { after: data.players.pageInfo.endCursor },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newNodes = fetchMoreResult.players.nodes;
				return {
					players: {
						pageInfo: fetchMoreResult.players.pageInfo,
						nodes: [...previousResult.players.nodes, ...newNodes],
						__typename: previousResult.players.__typename,
					},
				};
			},
		});
	};

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}
	if(!data?.players?.nodes || !data?.players?.pageInfo) {
		return { players: null, pageInfo: null };
	}

	return {
		players: [...data.players.nodes],
		pageInfo: data.players.pageInfo,
		fetchNextBatch,
	};
};

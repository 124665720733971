import {gql} from '@apollo/client';
import {KIT_DATA} from "./fragments/KIT_DATA";

export const GET_TEAMS = gql`
query getTeams(
	$after: String, 
	$perPage: Int,
	$divisions: [String],
	$search: String
) {
  teams(
    first: $perPage
    after: $after
    where: {
      orderby: {field: TITLE, order: ASC}, 
      divisions: $divisions,
      search: $search
    }
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      total
    }
    nodes {
      uri
      title
      databaseId
      slug
      teamData {
        kits {
          homeKit {
            ...KitData
          }
        }
      }
      divisions {
        nodes {
          databaseId
          slug
          uri
          name
        }
      }
    }
  }
}
${KIT_DATA}
`;
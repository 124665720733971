import { NavLink } from "react-router-dom";
import { memo } from "react";

const Player = ({ data }) => {
	const { title, uri } = data;

	console.log('Player', title);

	return (
		<li>
			<NavLink to={uri}>{title}</NavLink>
		</li>
	);
};

export default memo(Player, (prevProps, nextProps) => {
	return prevProps.data.uri === nextProps.data.uri;
});
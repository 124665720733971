import {useTranslation} from "../../context/I18nContext";

const Kit = ({ data }) => {

	console.log('Kit', data);

	const noKit = useTranslation('match_centre_no_kit');
	const { kitData } = { ...data };

	return (
		<div className="kit">
			<h4>Kit</h4>
			{
				data
					? (
						<>
							<div>ID: { data.databaseId }</div>
							<div>Design: { kitData.design }</div>
							<div>Shirt 1: { kitData.shirt1 }</div>
							<div>Shirt 2: { kitData.shirt2 }</div>
							<div>Shirt 3: { kitData.shirt3 }</div>
							<div>Shorts 1: { kitData.shorts1 }</div>
							<div>Shorts 2: { kitData.shorts2 }</div>
							<div>Goalkeeper 1: { kitData.goalkeeper1 }</div>
						</>
					)
					: <em>{noKit}</em>
			}
		</div>
	);
}

export default Kit;
import {useTranslation} from "../../context/I18nContext";

const RollButton = ({ onClick, disabled = false, color1 = null, color2 = null, }) => {

	const label = useTranslation('dice_roll_button');

	return (
		<button
			onClick={ onClick }
			disabled={ disabled }
			style={{background: color1, color: color2, marginBottom: "1em"}}
		>
			<span>{ label }</span>
		</button>
	);

}

export default RollButton;
import {gql} from "@apollo/client";
import {PLAYER_DATA_STATS} from "./fragments/PLAYER_DATA_STATS";
import {PLAYER_DATA_AVAILABILITY} from "./fragments/PLAYER_DATA_AVAILABILITY";

export const GET_PLAYER_BY_URI = gql`
query getPlayerByUri($uri: String!) {
  nodeByUri(uri: $uri) {
    __typename
    ... on Player {
      id
      databaseId
      slug
      title
      playerData {
        age
        nationality
        u21
        international
        playingStatus
        preferredFoot
        shirtNumber
        currentTeam {
          ... on Team {
            slug
            uri
            title
          }
        }
        positions {
          name
          slug
          positionData {
            abbreviation
          }
        }
        sides {
          name
          slug
          positionData {
            abbreviation
          }
        }
        stats {
          ...PlayerDataStats
        }
        availability {
          ...PlayerDataAvailability
        }
      }
    }
  }
}
${PLAYER_DATA_STATS}
${PLAYER_DATA_AVAILABILITY}
`;
import {GET_FILTER_OPTIONS_SIDES} from "../graphql-queries/GET_FILTER_OPTIONS_SIDES";
import {useGraphqlLazyQuery} from "./useGraphqlLazyQuery";

export const useGetFilterOptionsSides = () => {

	const { executeQuery, loading, data, error } = useGraphqlLazyQuery(GET_FILTER_OPTIONS_SIDES);

	const getFilterOptions = () => {
		executeQuery().then(r => {});
	};

	if(loading) {
		return { getFilterOptions, loading };
	}
	if(error) {
		return { getFilterOptions, error };
	}
	if(!executeQuery || !data?.sides?.nodes) {
		return { getFilterOptions, data: null };
	}

	return { getFilterOptions, data: data.sides.nodes };
};
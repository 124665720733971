import {memo} from "react";

const Stat = ({
	value,
	modifiedValue = null,
	label,
	prefix = null,
	suffix = null,
	className = null,
	includeBaseStat= true,
	includeLabel = true,
}) => {

	const modifierDifference = ( modifiedValue ? modifiedValue - value : null );

	console.log('Stat', value, modifiedValue, modifierDifference, label);

	return (
		<div className={`stat ${className}`}>
			{ prefix && <span className="prefix">{prefix}</span> }

			{
				modifiedValue
					? <>
						<strong className="modified-value">{modifiedValue}</strong>&nbsp;
						{
							includeBaseStat
							? <>
								(
									<span className="value" style={{color: "#666"}}>{!value || value === 0 ? 0 : value}</span>
									{
										modifierDifference || modifierDifference === 0
											? <span className="modifier-difference" style={{color: "#666"}}> {modifierDifference >= 0 ? `+ ${modifierDifference}` : `- ${Math.abs(modifierDifference)}` }</span>
											: null
									}
								)
							</>
							: null
						}
					</>
					: <span className="value">{!value || value === 0 ? 0 : value}</span>
			}

			{ suffix && <span className="suffix">{suffix}</span> }

			{ label && includeLabel && ` (${label})` }
		</div>
	);
}

export default memo(Stat, (prevProps, nextProps) => {
	return prevProps.value === nextProps.value
		&& prevProps.modifiedValue === nextProps.modifiedValue
		&& prevProps.label === nextProps.label
		&& prevProps.prefix === nextProps.prefix
		&& prevProps.suffix === nextProps.suffix
		&& prevProps.className === nextProps.className
		&& prevProps.includeBaseStat === nextProps.includeBaseStat
		&& prevProps.includeLabel === nextProps.includeLabel;
});
import {useGraphqlQuery} from "./useGraphqlQuery";
import {GET_REFEREES} from "../graphql-queries/GET_REFEREES";

export const useGetReferees = () => {

	const { loading, error, data } = useGraphqlQuery(GET_REFEREES);

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}

	if(!data?.referees?.nodes) {
		return { data: null };
	}

	return { data: { ...data.referees.nodes } };
};
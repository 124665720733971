import {gql} from '@apollo/client';

export const GET_PLAYERS = gql`
query getPlayers(
	$after: String, 
	$perPage: Int, 
	$playingStatus: [String], 
	$countries: [String], 
	$internationals: [String], 
	$teams: [ID], 
	$sides: [String], 
	$positions: [String], 
	$search: String
) {
  players(
    first: $perPage
    after: $after
    where: {
      orderby: {field: TITLE, order: ASC}, 
      playingStatus: $playingStatus, 
      countries: $countries, 
      internationals: $internationals, 
      teams: $teams, 
      sides: $sides, 
      positions: $positions, 
      search: $search
    }
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      total
    }
    nodes {
      slug
      uri
      title
    }
  }
}
`;
import LineupPlayers from "./LineupPlayers";

const Lineup = ({data}) => {

	console.log('Lineup', data);
	const { starting11, substitutes } = { ...data };

	return (
		<div className="lineup">
			<h4>Starting 11</h4>
			{
				starting11
					? <LineupPlayers players={ starting11 } />
					: <div><em>No starting 11</em></div>
			}
			<h4>Substitutes</h4>
			{
				substitutes
					? <LineupPlayers players={ substitutes } />
					: <div><em>No substitutes</em></div>
			}
		</div>
	);
}

export default Lineup;
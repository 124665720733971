import { useTranslate } from "../../../../context/I18nContext";
import Stat from "./Stat";
import Morale from "./Morale";
import Fitness from "./Fitness";

const Stats = ({ stats, displayStats = ["tech", "tac", "phys", "overall", "morale", "fitness"], includeBaseStats= true, includeLabels = true }) => {

	const translate = useTranslate();
	console.log("Stats", stats, displayStats);

	const statComponents = displayStats.map((stat) => {

		const value = stats[stat] ? stats[stat] : null;

		switch(stat) {
			case "tech":
			case "tac":
			case "phys":
			case "overall":
				const fitnessMultiplier = (stats.fitness ?? 0) / 100;
				const moraleMultiplier = Math.floor((stats.morale ?? 0) / 10);
				const modifiedValue = Math.floor(value * fitnessMultiplier) + moraleMultiplier;

				return <Stat
					key={stat}
					value={value}
					modifiedValue={ modifiedValue }
					includeBaseStat={ includeBaseStats }
					includeLabel={ includeLabels }
					label={translate(stat)}
				/>;

			case "morale":
				return <Morale
					key={stat}
					value={value}
				/>;

			case "fitness":
				return <Fitness
					key={stat}
					value={value}
				/>;

			default:
				return <Stat
					key={stat}
					value={value}
					label={`Undefined stat: ${stat}`}
				/>;
		}
	});

	return <div className="stats">{statComponents}</div>;
};

export default Stats;

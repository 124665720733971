import {gql} from "@apollo/client";
import {FIXTURE_DATA_TEAM} from "./fragments/FIXTURE_DATA_TEAM";
import {KIT_DATA} from "./fragments/KIT_DATA";
import {PLAYER_DATA_STATS} from "./fragments/PLAYER_DATA_STATS";
import {FIXTURE_LINEUP_PLAYER} from "./fragments/FIXTURE_LINEUP_PLAYER";

export const GET_FIXTURE_BY_URI = gql`
query getFixtureByUri($uri: String!) {
  nodeByUri(uri: $uri) {
    __typename
    ... on Fixture {
      id
      databaseId
      slug
      fixtureData {
        season
        round
        homeTeam {
          ...FixtureDataTeam
        }
        homeScore
        awayTeam {
          ...FixtureDataTeam
        }
        awayScore
        fixtureType {
          databaseId
          name
          slug
        }
        division {
          databaseId
          name
          slug
          uri
        }
      }
      matchCentre {
        weather
        mom {
          ... on Player {
            slug
            uri
            title
          }
        }
        referee {
          ... on Referee {
            uri
            slug
            title
          }
        }
        kitHomeTeam {
          ...KitData
        }
        kitAwayTeam {
          ...KitData
        }
        bonusHomeTeam
        formCheckHomeTeam
        bonusAwayTeam
        formCheckAwayTeam
        matchEvents {
          ... on Fixture_Matchcentre_MatchEvents_Event {
            minute
            matchEventType
            teamInvolved
            player1Involved {
              ... on Player {
                slug
                uri
                title
              }
            }
            player1InvolvedExtra
            player2Involved {
              ... on Player {
                slug
                uri
                title
              }
            }
            player2InvolvedExtra
          }
        }
        lineupHomeTeam {
          starting11 {
            position
            captain
            player {
              ...FixtureLineupPlayer
            }
          }
          substitutes {
            player {
              ...FixtureLineupPlayer
            }
          }
        }
        lineupAwayTeam {
          starting11 {
            position
            captain
            player {
              ...FixtureLineupPlayer
            }
          }
          substitutes {
            player {
              ...FixtureLineupPlayer
            }
          }
        }
      }
    }
  }
}
${FIXTURE_LINEUP_PLAYER}
${FIXTURE_DATA_TEAM}
${KIT_DATA}
${PLAYER_DATA_STATS}
`;
import {memo} from "react";

import Seasons from "../filters/Seasons";

const Filters = ({ onChange, defaultValues = null }) => {

	const getDefaultValue = (type) => {
		return defaultValues?.[type] ?? null;
	};

	return (
		<div className="filters">
			<h3>Filters</h3>
			<div style={{display: "flex", flexWrap: "wrap", minWidth: "300px", margin: "0 0 2em"}}>
				<Seasons
					defaultValue={ getDefaultValue('season') }
					onChange={(values) => onChange('season', values)}
					multi={false}
				/>
			</div>
		</div>
	);
}

export default memo(Filters, (prevProps, nextProps) => {
	return prevProps.defaultValues === nextProps.defaultValues;
});
import {useGraphqlLazyQuery} from "./useGraphqlLazyQuery";
import {GET_FILTER_OPTIONS_DIVISIONS} from "../graphql-queries/GET_FILTER_OPTIONS_DIVISIONS";

export const useGetFilterOptionsDivisions = () => {

	const { executeQuery, loading, data, error } = useGraphqlLazyQuery(GET_FILTER_OPTIONS_DIVISIONS);

	const getFilterOptions = () => {
		executeQuery().then(r => {});
	};

	if(loading) {
		return { getFilterOptions, loading };
	}
	if(error) {
		return { getFilterOptions, error };
	}
	if(!executeQuery || !data?.divisions?.nodes) {
		return { getFilterOptions, data: null };
	}

	return { getFilterOptions, data: data.divisions.nodes };
};
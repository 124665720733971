import {memo} from "react";

const Sides = ({sides, abbreviationOnly= false}) => {

	console.log('Sides', sides, abbreviationOnly);

	return (
		<div>
			{
				sides.map(side => {
					return (
						<div className="side" key={side.slug}>{ abbreviationOnly ? side.positionData.abbreviation : side.name }&nbsp;</div>
					);
				})
			}
		</div>
	);
}

export default memo(Sides, (prevProps, nextProps) => {
	return prevProps.sides === nextProps.sides
		&& prevProps.abbreviationOnly === nextProps.abbreviationOnly;
});
import { useMemo } from 'react';

export const useTeamAverageAge = (players) => {
	return useMemo(() => {
		if(!players) {
			return 0;
		}
		const sum = players.reduce((acc, cur) => {
			return acc + (cur?.player?.playerData?.age ?? 0);
		}, 0);
		const average = sum / players.length;
		return average.toFixed(2);
	}, [players]);
}
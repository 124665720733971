import AverageAge from "./stats/AverageAge";
import PositionAverage from "./stats/PositionAverage";
import TeamStatAverage from "./stats/TeamStatAverage";
import InternationalCount from "./stats/InternationalCount";
import ForeignPlayerCount from "./stats/ForeignPlayerCount";

const Stats = ({players, bonus, formCheck, season}) => {
	console.log('Stats', players, bonus, formCheck, season);

	const modifier = bonus + formCheck;

	if(!players) {
		return (
			<div className="stats">
				<h4>Stats</h4>
				<em>No players selected</em>
			</div>
		);
	}

	return (
		<div className="stats">
			<h4>Stats</h4>
			<div>Bonus: { bonus ? ( bonus > 0 ? `+${bonus}` : bonus ) : 0 }</div>
			<div>Form Check: { formCheck ? ( formCheck > 0 ? `+${formCheck}` : formCheck ) : 0 }</div>
			<AverageAge
				players={ players }
				season={ season }
			/>
			<PositionAverage
				players={ players }
				modifier={ modifier }
				position="GK"
				label="Goalkeeper"
			/>
			<PositionAverage
				players={ players }
				modifier={ modifier }
				position="D"
				label="Defence"
			/>
			<PositionAverage
				players={ players }
				modifier={ modifier }
				position="M"
				label="Midfield"
			/>
			<PositionAverage
				players={ players }
				modifier={ modifier }
				position="A"
				label="Attack"
			/>
			<TeamStatAverage
				players={ players }
				stat="overall"
				modifier={ modifier }
				label="Team"
			/>
			<TeamStatAverage
				players={ players }
				stat="tac"
				modifier={ modifier }
				label="Tactic"
			/>
			<TeamStatAverage
				players={ players }
				stat="phys"
				modifier={ modifier }
				label="Form"
			/>
			<InternationalCount
				players={ players }
			/>
			<ForeignPlayerCount
				players={ players }
			/>
		</div>
	);
}

export default Stats;
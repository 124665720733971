const FixtureInfo = ({ division = null, fixtureType = null, season, round }) => {

	console.log('FixtureInfo', division, fixtureType, season, round);

	return (
		<div className="fixture-info">
			<h4>Fixture Info</h4>
			{ division ? division : null } { fixtureType }, Season {season}, Round {round}
		</div>
	);
};

export default FixtureInfo;
import {useGetLeagueTableByDivision} from "../../../hooks/useGetLeagueTableByDivision";
import {useCurrentSeason} from "../../../context/GameSettingsContext";
import {NavLink} from "react-router-dom";

const LeagueTable = ({ division, teamId, season = null }) => {

	const currentSeason = useCurrentSeason();

	const { loading, error, data } = useGetLeagueTableByDivision({
		division,
		season: ( season ? season : currentSeason ),
		teamId,
	});

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No League Table</p>

	console.log('LeagueTable', division, season, {data});

	const style = {padding: ".25em"};

	return (
		<>
			<h3>League Table snippet</h3>
			{
				data.length > 0
				? (
					<table className="league-table">
						<thead>
						<tr>
							<th style={style}>#</th>
							<th style={{textAlign: 'left'}}>Team</th>
							<th style={style}>Pl</th>
							<th style={style}>+/-</th>
							<th style={style}>Pts</th>
							<th style={{textAlign: 'left'}}>Form</th>
						</tr>
						</thead>
						<tbody>
						{
							data.map(entry => {

								const isActive = teamId === entry.team.databaseId;

								return (
									<tr key={entry.team.slug} style={ isActive ? {background: "#CCC"} : null}>
										<td style={style} >{entry.position}</td>
										<td style={{textAlign: 'left', minWidth: '200px'}}><NavLink to={entry.team.uri}>{entry.team.title}</NavLink></td>
										<td style={style} >{entry.played}</td>
										<td style={style} >{entry.difference}</td>
										<td style={style} >{entry.points}</td>
										<td style={style} >{entry.form}</td>
									</tr>
								);
							})
						}
						</tbody>
					</table>
				)
				: <p>No League Table</p>
			}
		</>
	);
};

export default LeagueTable;
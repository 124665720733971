import {useGetPlayersByTeam} from "../../../hooks/useGetPlayersByTeam";
import Player from "./Player";

const Players = ({ teamId }) => {

	const { loading, error, data } = useGetPlayersByTeam({teamId});

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error: {error.message}</p>;

	return (
	<div>
		<h3>Players</h3>
		<ul>
			{
				data.map(player => {
					return (
						<Player
							key={ player.node.slug }
							data={ player.node }
						/>
					);
				})
			}
		</ul>
	</div>
	);
}

export default Players;
import {NavLink} from "react-router-dom";

const TableEntry = ({data}) => {
	console.log('TableEntry', data);

	const style = {padding: ".25em"};

	return (
		<tr>
			<td style={style} >{data.position}</td>
			<td style={{textAlign: 'left', minWidth: '200px'}}><NavLink to={data.team.uri}>{data.team.title}</NavLink></td>
			<td style={style} >{data.played}</td>
			<td style={style} >{data.won}</td>
			<td style={style} >{data.drawn}</td>
			<td style={style} >{data.lost}</td>
			<td style={style} >{data.for}</td>
			<td style={style} >{data.against}</td>
			<td style={style} >{data.difference}</td>
			<td style={style} >{data.points}</td>
			<td style={style} >{data.form}</td>
		</tr>
	);
}

export default TableEntry;
import { gql } from '@apollo/client';

export const FIXTURE_DATA_TEAM = gql`
fragment FixtureDataTeam on Team {
  ... on Team {
    databaseId
    slug
    uri
    title
  }
}
`;
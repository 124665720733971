import {useGetLeagueTableByDivision} from "../../hooks/useGetLeagueTableByDivision";
import {useCurrentSeason} from "../../context/GameSettingsContext";

import TableHeader from "./TableHeader";
import TableFooter from "./TableFooter";
import TableEntry from "./TableEntry";

const LeagueTable = ({ division, season = null }) => {

	const currentSeason = useCurrentSeason();

	const { loading, error, data, lastUpdated } = useGetLeagueTableByDivision({
		division,
		season: ( season ? season : currentSeason ),
	});

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No League Table</p>

	console.log('LeagueTable', division, season, {data}, lastUpdated);

	return (
		<table className="league-table">
			<TableHeader />
			<tbody>
			{
				data.map(entry => {
					return (
						<TableEntry
							key={entry.team.slug}
							data={ entry }
						/>
					);
				})
			}
			</tbody>
			<TableFooter lastUpdated={ lastUpdated } />
		</table>
	);
};

export default LeagueTable;
import Select from "react-select";
import {useTranslate} from "../../context/I18nContext";
import {memo} from "react";

const Internationals = ({ onChange, defaultValue = null, multi = true }) => {

	const translate = useTranslate();

	const options = [
		{ value: 'international', label: translate('international') },
		{ value: 'u21', label: translate('u21') },
	];

	return (
		<div className="countries-filter">
			<label>
				{translate('filter_internationals_label')}
				<Select
					options={options}
					isMulti={multi}
					placeholder={translate('filter_internationals_placeholder')}
					onChange={onChange}
					defaultValue={defaultValue}
					value={defaultValue}
				/>
			</label>
		</div>
	);
};

export default memo(Internationals, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});
import Stats from "./team/Stats";
import Kit from "../kits/Kit";
import Lineup from "./team/Lineup";
import TeamDice from "../dice/TeamDice";
import Warnings from "./team/Warnings";

const Team = ({ data, season }) => {

	console.log('\nTeam', data.title, data, season);

	const { kit, lineup, bonus, formCheck } = { ...data };
	const { starting11 } = { ...lineup }
	const { kitData } = { ...kit };

	return (
		<div className="team">
			<h2>{ data.title }</h2>
			<TeamDice
				teamId={ data.databaseId }
				color1={ kitData && kitData.dice1 }
				color2={ kitData && kitData.dice2 }
			/>
			<Stats
				players={ starting11 }
				bonus={ bonus }
				formCheck={ formCheck }
				season={ season }
			/>
			<Warnings
				lineup={ lineup }
			/>
			<Kit data={ kit }/>
			<Lineup data={ lineup } />
		</div>
	);
}

export default Team;
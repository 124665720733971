import React, {createContext, useContext, useMemo} from 'react';
import moment, {unix} from "moment";

export const GeneralSettingsContext = createContext({});

export const GeneralSettingsProvider = ({ value, children }) => {
	return (
		<GeneralSettingsContext.Provider value={value}>
			{children}
		</GeneralSettingsContext.Provider>
	);
};

export function useDateFormat(timestamp, customFormat = null) {
	const generalSettings = useContext(GeneralSettingsContext);
	const dateFormat = customFormat || generalSettings['dateFormat'];

	return useMemo(() => {
		return moment(unix(timestamp)).format(phpToMoment(dateFormat));
	}, [timestamp, dateFormat]);
}
export function useTimeFormat(timestamp, customFormat = null) {
	const generalSettings = useContext(GeneralSettingsContext);
	const timeFormat = customFormat || generalSettings.timeFormat;

	return useMemo(() => {
		return moment.unix(timestamp).format(phpToMoment(timeFormat));
	}, [timestamp, timeFormat]);
}

export function useDateTimeFormat(timestamp, customFormat = null) {
	const generalSettings = useContext(GeneralSettingsContext);
	const dateTimeFormat = customFormat || `${generalSettings['dateFormat']} ${generalSettings['timeFormat']}`;

	return useMemo(() => {
		return moment.unix(timestamp).format(phpToMoment(dateTimeFormat));
	}, [timestamp, dateTimeFormat]);
}

export function useGeneralSetting(key) {
	const generalSettings = useContext(GeneralSettingsContext);
	return generalSettings[key] || key;
}
export function useGeneralSettings() {
	const generalSettings = useContext(GeneralSettingsContext);
	return (key) => generalSettings[key] || key;
}

const phpToMoment = (str) => {
	const replacements = {
		'd' : 'DD',
		'D' : 'ddd',
		'j' : 'D',
		'l' : 'dddd',
		'N' : 'E',
		'S' : 'o',
		'w' : 'e',
		'z' : 'DDD',
		'W' : 'W',
		'F' : 'MMMM',
		'm' : 'MM',
		'M' : 'MMM',
		'n' : 'M',
		't' : '', // no equivalent
		'L' : '', // no equivalent
		'o' : 'YYYY',
		'Y' : 'YYYY',
		'y' : 'YY',
		'a' : 'a',
		'A' : 'A',
		'B' : '', // no equivalent
		'g' : 'h',
		'G' : 'H',
		'h' : 'hh',
		'H' : 'HH',
		'i' : 'mm',
		's' : 'ss',
		'u' : 'SSS',
		'e' : 'zz', // deprecated since Moment.js 1.6.0
		'I' : '', // no equivalent
		'O' : '', // no equivalent
		'P' : '', // no equivalent
		'T' : '', // no equivalent
		'Z' : '', // no equivalent
		'c' : '', // no equivalent
		'r' : '', // no equivalent
		'U' : 'X'
	};
	return str.split('').map(chr => chr in replacements ? replacements[chr] : chr).join('');
}
import Referee from "./match-centre/Referee";
import Weather from "./match-centre/Weather";
import FixtureInfo from "./match-centre/FixtureInfo";
import MoM from "./match-centre/MoM";
import MatchEvents from "./match-centre/MatchEvents";

const MatchCentre = ({ matchCentreData, fixtureData }) => {

	const { matchEvents } = { ...matchCentreData };

	console.log('\nMatchCentre', matchCentreData, fixtureData);

	return (
		<div className="match-matchCentre">
			<h2>Match Centre</h2>

			<FixtureInfo
				division={ fixtureData?.division?.name ? fixtureData.division.name : null }
				fixtureType={ fixtureData.fixtureType.name }
				season={ fixtureData.season }
				round={ fixtureData.round }
			/>
			<Referee data={ matchCentreData.referee } />
			<Weather name={ matchCentreData.weather } />
			<MoM data={ matchCentreData.mom } />
			<MatchEvents
				events={ matchEvents }
				homeTeamId={ fixtureData.homeTeam.databaseId }
			/>

		</div>
	);
};

export default MatchCentre;
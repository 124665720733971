import MatchEvent from "./MatchEvent";

const MatchEvents = ({events, homeTeamId}) => {

	console.log('MatchEvents', events, homeTeamId);

	const runningScore = {
		home: 0,
		away: 0,
	};

	return (
		<div className="match-events">
			<h4>Events</h4>
			<div style={{display: "flex", flexDirection:"column-reverse"}}>
			{
				events.length > 0
					? events.map((matchEvent, index) => {
						const key = `match-event-${index}`;
						const isHomeTeam = ( matchEvent?.teamInvolved ? parseInt(matchEvent.teamInvolved) === homeTeamId : null );

						if(matchEvent.matchEventType === 'goal') {
							if(isHomeTeam) {
								runningScore.home++;
							}
							else {
								runningScore.away++;
							}
						}

						return (
							<MatchEvent
								key={ key }
								data={ matchEvent }
								isHomeTeam={ isHomeTeam }
								runningScore={ {...runningScore} }
							/>
						)
					})
					: <em>No match events</em>
			}
			</div>
		</div>
	);
}

export default MatchEvents;
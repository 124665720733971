import {useGraphqlQuery} from "./useGraphqlQuery";
import {useCurrentSeason} from "../context/GameSettingsContext";
import {GET_LEAGUE_TABLE_PROGRESS} from "../graphql-queries/GET_LEAGUE_TABLE_PROGRESS";

export const useGetLeagueTableProgress = ({division, teamId, season = null}) => {

	const currentSeason = useCurrentSeason();

	const { loading, error, data } = useGraphqlQuery(GET_LEAGUE_TABLE_PROGRESS, {
		variables: {
			division,
			teamId,
			season: season !== null ? season : currentSeason, // Note. This time we need season to be an integer
		},
	});

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}
	if(!data?.leagueTableProgress) {
		return { data: null };
	}

	return { data: data.leagueTableProgress };
};
import {memo} from "react";

const Positions = ({positions, abbreviationOnly= false}) => {

	console.log('Positions', positions, abbreviationOnly);

	return (
		<div>
			{
				positions.map(position => {
					return (
						<div className="position" key={position.slug}>{ abbreviationOnly ? position.positionData.abbreviation : position.name }&nbsp;</div>
					);
				})
			}
		</div>
	);
}

export default memo(Positions, (prevProps, nextProps) => {
	return prevProps.positions === nextProps.positions
		&& prevProps.abbreviationOnly === nextProps.abbreviationOnly;
});
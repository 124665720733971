import {gql} from '@apollo/client';

import { PLAYER_DATA_STATS, } from "./fragments/PLAYER_DATA_STATS";

export const GET_PLAYERS_BY_TEAM = gql`
query getPlayersByTeam($teamId: String!) {
  players(
    where: {metaQuery: {relation: AND, metaArray: [{compare: EQUAL_TO, key: "current_team", value: $teamId}, {compare: EQUAL_TO, key: "playing_status", value: "active"}]}, orderby: [{field: ACF_SHIRT_NUMBER, order: ASC}, {field: TITLE, order: ASC}]}
  ) {
    edges {
      node {
        title
        uri
        slug
        playerData {
          age
          stats {
            ...PlayerDataStats
          }
          shirtNumber
          preferredFoot
          nationality
          international
          u21
          positions {
            name
            positionData {
              abbreviation
            }
          }
          sides {
            name
            positionData {
              abbreviation
            }
          }
        }
      }
    }
  }
}
${PLAYER_DATA_STATS}
`;
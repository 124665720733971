import { useLazyQuery } from "@apollo/client";

export const useGraphqlLazyQuery = (query, options = {}) => {
	const [executeQuery, { loading, error, data, ...rest }] = useLazyQuery(
		query,
		options
	);

	return {
		executeQuery,
		loading,
		error,
		data,
		...rest,
	};
};
import {useCallback, useMemo} from "react";
import {useGetFixturesByTeam} from "../../../hooks/useGetFixturesByTeam";
import {useTranslation} from "../../../context/I18nContext";
import {useCurrentSeason} from "../../../context/GameSettingsContext";
import {useLocalStorage} from "../../../hooks/utils/useLocalStorage";

import Filters from "../../fixtures/Filters";
import FixturesByType from "./FixturesByType";

const Fixtures = ({ teamId }) => {

	const noFixtures = useTranslation('fixtures_no_fixtures');
	const currentSeason = useCurrentSeason();
	const initialFilterState = {
		season: [{ key: currentSeason, label: currentSeason }],
	};

	// The values returned from the filters. For <select> it will include { value, label } and plain text string for <input>
	const [selectedValues, setSelectedValues] = useLocalStorage(
		"FixturesSelectedValues",
		initialFilterState,
	);

	const handleFiltersChange = useCallback((filter, values) => {

		const previousValues = selectedValues[filter];
		const newValues = Array.isArray(values)
			? values.map((valueObject) => valueObject.value) // Values returned from <Select />
			: values; // Values returned from plain input field

		if(newValues !== previousValues) {
			setSelectedValues((prevSelectedOptions) => ({
				...prevSelectedOptions,
				[filter]: values,
			}));
		}
	}, [selectedValues, setSelectedValues]);

	const { loading, error, data } = useGetFixturesByTeam({
		teamId: teamId,
		season: selectedValues.season.value, // We expect only one season
	});


	const renderFixtures = useMemo(() => {
		if(!data) return null;

		const fixturesByTypeKeys = Object.keys(data);

		return (
			<div className="fixture-types" style={{display: "flex", justifyContent:"space-between", width: "90%"}}>
				{
					fixturesByTypeKeys.length > 0
						? data.map(fixtureType => {
							return (
								<FixturesByType
									key={ fixtureType.fixtureType.slug }
									fixtureType={ fixtureType.fixtureType }
									fixtures={ fixtureType.fixtures }
								/>
							)
						})
						:
						<em>{noFixtures}</em>
				}
			</div>
		);
	}, [data, noFixtures]);

	console.log('Fixtures', data);

	return (
	<div>
		<h3>Fixtures</h3>
		<Filters
			onChange={handleFiltersChange}
			defaultValues={selectedValues}
		/>

		{
			loading
				? <p>Loading...</p>
				: ( error ? <p>Error : {error.message}</p> : renderFixtures )
		}
	</div>
	);
}

export default Fixtures;
import { useState, useEffect } from 'react';
import { database } from '../../services/Firebase';
import { ref, push, onValue, off, query, orderByChild, limitToLast } from 'firebase/database';

export const useDiceRoll = ({teamId, limit = 5}) => {
	const collectionName = `dice/rolls/${teamId}`;

	const [data, setData] = useState([]);

	useEffect(() => {
		const collectionRef = ref(database, `${collectionName}`);
		const queryConstraints = [
			orderByChild('timestamp'),
			limitToLast(limit)
		];
		const queryRef = query(collectionRef, ...queryConstraints);

		const listener = onValue(queryRef, (snapshot) => {
			const data = snapshot.val();
			if(data) {
				setData(Object.values(data).reverse());
			}
		});

		// return a function that removes the listener
		return () => off(queryRef, 'value', listener);
	}, [collectionName, limit]);

	const saveData = (newData) => {
		const collectionRef = ref(database, `${collectionName}`);
		push(collectionRef, newData);
	};

	return [data, saveData];
}
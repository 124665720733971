import {useCallback, useMemo} from "react";
import {useGetTeams} from "../hooks/useGetTeams";
//import {usePostsPerPage} from "../context/ReadingSettingsContext";
import {useLocalStorage} from "../hooks/utils/useLocalStorage";

import Team from "../components/teams/Team";
import Filters from "../components/teams/Filters";

const initialFilterState = {
	divisions: [],
	search: null,
};

const TeamsPage = () => {

	const perPage = 9999;//usePostsPerPage();

	// The actual filter values we use in our "team search"
	const [searchValues, setSearchValues] = useLocalStorage(
		"TeamsSearchValues",
		initialFilterState
	);

	// The values returned from the filters. For <select> it will include { value, label } and plain text string for <input>
	const [selectedValues, setSelectedValues] = useLocalStorage(
		"TeamsSelectedValues",
		initialFilterState
	);

	const { loading, error, teams, pageInfo, fetchNextBatch } = useGetTeams({
		perPage,
		...searchValues,
	});

	const memoizedTeams = useMemo(() => {
		if(!teams) return null;
		return teams.map((team) => (
			<Team
				key={team.slug}
				data={team}
			/>
		));
	}, [teams]);

	const handleFiltersChange = useCallback((filter, values) => {

		const previousValues = searchValues[filter];
		const newValues = Array.isArray(values)
			? values.map((valueObject) => valueObject.value) // Values returned from <Select />
			: values; // Values returned from plain input field

		if(newValues !== previousValues) {
			setSearchValues((prevFilterValues) => ({
				...prevFilterValues,
				[filter]: newValues,
			}));

			setSelectedValues((prevSelectedOptions) => ({
				...prevSelectedOptions,
				[filter]: values,
			}));
		}
	}, [searchValues, setSearchValues, setSelectedValues]);


	const handleResetFilters = () => {
		setSearchValues(initialFilterState);
		setSelectedValues(initialFilterState);
	};

	if(error) return <p>Error : {error.message}</p>;

	const remainingTeams = Math.max(0, pageInfo?.total - perPage);
	const totalTeams = teams?.length + remainingTeams;

	console.log('TeamsPage', teams);

	return (
		<div className="page team-page">
			<h1>TEAMS PAGE</h1>

			<Filters
				onChange={handleFiltersChange}
				onReset={handleResetFilters}
				defaultValues={selectedValues}
			/>

			<div>
				Total Number of Teams matching the filters:{" "}
				{!isNaN(totalTeams) ? totalTeams : null}
			</div>
			<div style={{marginBottom: "1em"}}>Teams remaining: {!isNaN(remainingTeams) ? remainingTeams : null}</div>

			{loading ? (
				<p>Loading...</p>
			) : totalTeams > 0 ? (
				<>
					<ul>{memoizedTeams}</ul>
					{pageInfo?.hasNextPage && (
						<button onClick={() => fetchNextBatch()} style={{marginTop: "1em"}}>
							{loading ? "Loading..." : "Load More"}
						</button>
					)}
				</>
			) : (
				<p>No Teams matching the filters</p>
			)}
			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li>Kits</li>
					<li>Location</li>
				</ul>
			</div>
		</div>
	)
}

export default TeamsPage;
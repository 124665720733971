import {useTeamWarnings} from "../../../hooks/game/useTeamWarnings";

const Warnings = ({lineup}) => {

	const errors = useTeamWarnings( {
		starting11: lineup.starting11,
		substitutes: lineup.substitutes,
	});
	console.log('Warnings', errors);

	return (
		<div>
			<h4>Team Warnings</h4>
			{
				errors.length > 0 && ( lineup.starting11 || lineup.substitutes )
					?
						<ul>
						{
							errors.map((error, index) => (
								<li key={index}>{error}</li>
							))
						}
						</ul>
					: <em>No Team Warnings</em>
			}
		</div>
	);
}

export default Warnings;
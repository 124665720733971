import {memo} from "react";
import {useTranslation} from "../../../../context/I18nContext";

import Injury from "./Injury";
import Suspension from "./Suspension";

const Availability = ({availability, iconsOnly = false}) => {

	const availableTranslation = useTranslation('player_data_available');

	console.log('Availability', availability);

	return (
		<div className="availability">
			{ availability.injured && <Injury detail={ availability.injuryDetail } iconOnly={ iconsOnly } /> }
			{ availability.suspended && <Suspension detail={ availability.suspensionDetail } iconOnly={ iconsOnly } /> }
			<div className="available">{ !availability.injured && !availability.suspended && availableTranslation }</div>
		</div>
	);

};

export default memo(Availability, (prevProps, nextProps) => {
	return prevProps.availability.injured === nextProps.availability.injured
		&& prevProps.availability.injuryDetail === nextProps.availability.injuryDetail
		&& prevProps.availability.suspended === nextProps.availability.suspended
		&& prevProps.availability.suspensionDetail === nextProps.availability.suspensionDetail
		&& prevProps.iconsOnly === nextProps.iconsOnly;
});
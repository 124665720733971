import { gql } from '@apollo/client';

export const KIT_DATA = gql`
fragment KitData on Kit {
  ... on Kit {
    databaseId
    kitData {
      design
      shirt1
      shirt2
      shirt3
      shorts1
      shorts2
      goalkeeper1
      dice1
      dice2
    }
  }
}
`;
import { createContext, useContext } from 'react';

export const I18nContext = createContext({});

export const I18nProvider = ({ value, children }) => {
	return (
		<I18nContext.Provider value={value}>
			{children}
		</I18nContext.Provider>
	);
};

export function useTranslation(key) {
	const i18n = useContext(I18nContext);
	return i18n[key] || key;
}
export function useTranslate() {
	const i18n = useContext(I18nContext);
	return (key) => i18n[key] || key;
}
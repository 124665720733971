import {GET_FILTER_OPTIONS_POSITIONS} from "../graphql-queries/GET_FILTER_OPTIONS_POSITIONS";
import {useGraphqlLazyQuery} from "./useGraphqlLazyQuery";

export const useGetFilterOptionsPositions = () => {

	const { executeQuery, loading, data, error } = useGraphqlLazyQuery(GET_FILTER_OPTIONS_POSITIONS);

	const getFilterOptions = () => {
		executeQuery().then(r => {});
	};

	if(loading) {
		return { getFilterOptions, loading };
	}
	if(error) {
		return { getFilterOptions, error };
	}
	if(!executeQuery || !data?.positions?.nodes) {
		return { getFilterOptions, data: null };
	}

	return { getFilterOptions, data: data.positions.nodes };
};
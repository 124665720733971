import {memo, useContext, useEffect, useState} from "react";
import {PlayingStatusesContext} from "../../context/PlayingStatusesContext";
import {useTranslate} from "../../context/I18nContext";
import Select from "react-select";

const PlayingStatuses = ({ onChange, defaultValue = null, multi = true }) => {

	const translate = useTranslate();
	const playingStatuses = useContext(PlayingStatusesContext);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if(playingStatuses) {
			const playingStatusOptions = playingStatuses.map((status) => ({
				label: status.value,
				value: status.key,
			}));
			setOptions(playingStatusOptions);
		}
	}, [playingStatuses]);

	return (
		<div>
			<label>
				{translate('filter_playing_status_label')}
				<Select
					options={options}
					isMulti={multi}
					placeholder={translate('filter_playing_status_placeholder')}
					onChange={onChange}
					defaultValue={defaultValue}
					value={defaultValue}
					//noOptionsMessage
				/>
			</label>
		</div>
	);
};

export default memo(PlayingStatuses, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});
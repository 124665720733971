import {useGetReferees} from "../hooks/useGetReferees";
import Referee from "../components/referees/Referee";

const RefereesPage = () => {

	const { loading, error, data } = useGetReferees();

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No Referees</p>

	return (
		<div className="page referees-page">
			<h1>REFEREES PAGE</h1>
			<ul>
			{
				Object.keys(data).map(key => {
					return (
						<Referee
							key={ key }
							data={ data[key] }
						/>
					)
				})
			}
			</ul>
			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li></li>
				</ul>
			</div>
		</div>
	)
}

export default RefereesPage;
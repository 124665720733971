import {gql} from "@apollo/client";
import {KIT_DATA} from "./fragments/KIT_DATA";
import {TEAM_DATA_MANAGER_FRAGMENT} from "./fragments/TEAM_DATA_MANAGER_FRAGMENT";
import {TEAM_DATA_TACTICS_FRAGMENT} from "./fragments/TEAM_DATA_TACTICS_FRAGMENT";
import {TEAM_DATA_STADIUM_FRAGMENT} from "./fragments/TEAM_DATA_STADIUM_FRAGMENT";

export const GET_TEAM_BY_URI = gql`
query getTeamByUri($uri: String!) {
  nodeByUri(uri: $uri) {
    __typename
    ... on Team {
      id
      databaseId
      slug
      title
      uri
      divisions {
        nodes {
          databaseId
          name
          slug
          uri
        }
      }
      teamData {
        manager {
          ...TeamDataManager
        }
        stadium {
          ...TeamDataStadium
        }
        tactics {
          ...TeamDataTactics
        }
        kits {
          homeKit {
            ...KitData
          }
          awayKit {
            ...KitData
          }
          thirdKit {
            ...KitData
          }
        }
      }
    }
  }
}
${KIT_DATA}
${TEAM_DATA_MANAGER_FRAGMENT}
${TEAM_DATA_TACTICS_FRAGMENT}
${TEAM_DATA_STADIUM_FRAGMENT}
`;
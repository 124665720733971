import {useCurrentSeason} from '../../context/GameSettingsContext';
import {useMemo} from "react";

export const useCalculatedAge = ({age, season = null}) => {
	let seasonOffet = useCurrentSeason();
	if(season) {
		seasonOffet = season;
	}

	return useMemo(() => {
		if(!age) {
			return null;
		}
		const calculatedAge = seasonOffet - age;
		return calculatedAge < 0 ? Math.abs(calculatedAge) : calculatedAge;
	}, [seasonOffet, age]);
};
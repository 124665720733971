export const useTeamData = (data, isHome = true) => {

	if(!data?.fixtureData && !data?.matchCentre) {
		return null;
	}

	const { fixtureData, matchCentre } = { ...data };
	const { homeTeam, awayTeam } = fixtureData;

	// Determine whether to use homeTeam or awayTeam based on isHome flag
	const teamData = isHome ? homeTeam : awayTeam;

	// Create new team object with modified fields
	return {
		...teamData,
		kit: isHome ? matchCentre.kitHomeTeam : matchCentre.kitAwayTeam,
		bonus: isHome ? matchCentre.bonusHomeTeam : matchCentre.bonusAwayTeam,
		formCheck: isHome ? matchCentre.formCheckHomeTeam : matchCentre.formCheckAwayTeam,
		lineup: isHome ? matchCentre.lineupHomeTeam : matchCentre.lineupAwayTeam,
		score: isHome ? fixtureData.homeScore : fixtureData.awayScore,
	};
};

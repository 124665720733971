import {NavLink} from "react-router-dom";

const Fixture = ({ data }) => {

	const { uri, fixtureData } = { ...data };
	const { homeTeam, awayTeam, homeScore, awayScore } = { ...fixtureData };

	console.log('Fixture', uri);

	return (
		<div style={{margin: ".5em 0"}}>
			<NavLink to={homeTeam.uri}>{homeTeam.title}</NavLink> vs <NavLink to={awayTeam.uri}>{awayTeam.title}</NavLink>&nbsp;
			{ (homeScore || homeScore === 0) && (awayScore || awayScore === 0) ? `${homeScore} - ${awayScore}` : null }&nbsp;&nbsp;
			(<NavLink to={ uri }>View match</NavLink>)
		</div>
	);
}

export default Fixture;
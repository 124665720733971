import {gql} from "@apollo/client";

export const GET_FILTER_OPTIONS_DIVISIONS = gql(`
query getFilterOptionsDivisions {
  divisions(first: 9999) {
    nodes {
      slug
      name
    }
  }
}
`);
import {createContext, useContext, useMemo} from 'react';

export const PlayingStatusesContext = createContext({});

export const PlayingStatusesProvider = ({ value, children }) => {
	return (
		<PlayingStatusesContext.Provider value={value}>
			{children}
		</PlayingStatusesContext.Provider>
	);
};

export function usePlayingStatusName(key) {
	const playingStatuses = useContext(PlayingStatusesContext);
	const playingStatusesMap = useMemo(() => {
		return playingStatuses.reduce((map, playingStatus) => {
			map[playingStatus.key] = playingStatus.value;
			return map;
		}, {});
	}, [playingStatuses]);

	return playingStatusesMap[key] || key;
}

export function usePlayingStatuses() {
	const playingStatuses = useContext(PlayingStatusesContext);
	const playingStatusesMap = useMemo(() => {
		return playingStatuses.reduce((map, playingStatus) => {
			map[playingStatus.key] = playingStatus.value;
			return map;
		}, {});
	}, [playingStatuses]);

	return (key) => {
		return playingStatusesMap[key] || key;
	};
}
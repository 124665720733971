import {GET_FILTER_OPTIONS_TEAMS} from "../graphql-queries/GET_FILTER_OPTIONS_TEAMS";
import {useGraphqlLazyQuery} from "./useGraphqlLazyQuery";

export const useGetFilterOptionsTeams = () => {

	const { executeQuery, loading, data, error } = useGraphqlLazyQuery(GET_FILTER_OPTIONS_TEAMS);

	const getFilterOptions = () => {
		executeQuery().then(r => {});
	};

	if(loading) {
		return { getFilterOptions, loading };
	}
	if(error) {
		return { getFilterOptions, error };
	}
	if(!executeQuery || !data?.teams?.nodes) {
		return { getFilterOptions, data: null };
	}

	return { getFilterOptions, data: data.teams.nodes };
};
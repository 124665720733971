import Player from "./Player";
import NoPlayer from "./NoPlayer";

const LineupPlayers = ({players}) => {

	console.log('LineupPlayers', players);

	return (
		<ul className="lineup-players">
			{
				players.map((player, index) => {

					const playerData = player.player;

					if(!playerData) {
						return <NoPlayer key={`no-player-${index}`} />
					}

					// Prepend index to create unique key. We might have put the same player in another position...
					return (
						<Player
							key={ `${index}-${playerData.slug}` }
							data={ player }
						/>
					);
				})
			}
		</ul>
	);
}

export default LineupPlayers;
import {useTeamStatAverage} from "../../../../hooks/game/useTeamStatAverage";

const TeamStatAverage = ({ players, stat, modifier = 0, label}) => {

	console.log('TeamStatAverage', stat);
	const average = useTeamStatAverage({
		players,
		stat,
		modifier,
	});

	return (
		<div>{label}: { average }</div>
	);
}

export default TeamStatAverage;
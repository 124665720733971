import { memo } from "react";
import { useTranslate } from "../../../context/I18nContext";

const PreferredFoot = ({ preferredFoot }) => {
	const translate = useTranslate();

	console.log("PreferredFoot", preferredFoot);

	const displayText = preferredFoot.includes("left") && preferredFoot.includes("right")
		? translate("player_data_preferred_foot_both")
		: preferredFoot.map(pf => translate(`player_data_preferred_foot_${pf}`)).join(" ");

	return <div>Preferred foot: {displayText}</div>;
};

export default memo(PreferredFoot, (prevProps, nextProps) => {
	return prevProps.preferredFoot === nextProps.preferredFoot;
});
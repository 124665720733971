import {NavLink} from "react-router-dom";
import {useTranslation} from "../../../context/I18nContext";

const Referee = ({ data }) => {

	console.log('Referee', data);
	const noReferee = useTranslation('match_centre_no_referee');

	return (
		<div className="referee">
			<h4>Referee</h4>
			{ data ? <NavLink to={data.uri}>{ data.title }</NavLink> : <em>{noReferee}</em> }
		</div>
	);

};

export default Referee;
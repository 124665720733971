import { useGraphqlQuery } from "./useGraphqlQuery";
import { GET_FIXTURES_BY_TYPES } from "../graphql-queries/GET_FIXTURES_BY_TYPES";
import { GET_FIXTURES_BY_TYPES_AND_DIVISION } from "../graphql-queries/GET_FIXTURES_BY_TYPES_AND_DIVISION";
import {useCurrentSeason} from "../context/GameSettingsContext";

export const useGetFixturesByTypes = ({ fixtureTypes = [], division, season = null }) => {
	const currentSeason = useCurrentSeason();

	const fixtureTypesAsStrings = fixtureTypes.map(type => String(type));

	const variables = {
		fixtureTypes: fixtureTypesAsStrings,
		season: String(season !== null ? season : currentSeason),
		division: String(division),
	};

	const query = ( division ? GET_FIXTURES_BY_TYPES_AND_DIVISION : GET_FIXTURES_BY_TYPES );

	const { loading, error, data } = useGraphqlQuery(query, {
		variables
	});

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}

	if(!data?.fixtures?.nodes) {
		return { data: null };
	}

	// Create a new object that maps each round number to an array of fixtures with that round number
	const fixturesByRound = {};
	data.fixtures.nodes.forEach((fixture) => {
		const roundNumber = fixture.fixtureData.round;
		const roundAlternateName = fixture.fixtureData.roundAlternateName;
		if(!fixturesByRound[roundNumber]) {
			fixturesByRound[roundNumber] = {
				fixtures: [],
				alternateNames: [],
			};
		}
		fixturesByRound[roundNumber].fixtures.push(fixture);
		if(roundAlternateName) {
			if(!fixturesByRound[roundNumber].alternateNames.includes(roundAlternateName)) {
				fixturesByRound[roundNumber].alternateNames.push(roundAlternateName);
			}
		}
	});

	return { data: [...data.fixtures.nodes], fixturesByRound: fixturesByRound };
};
import {memo} from "react";

const Die = ({ value, secret, rolling = false, digit = 10, color1 = null, color2 = null, }) => {

	const formattedValue = ( secret || (!value && value !== 0) || rolling
			? '??'
			: (value < 10 && digit === 10 ? `0${value}` : String(value))
	);

	console.log('Die', value, secret, rolling, color1, color2);

	return (
		<div className="die" style={{background: color1, color: color2}}>{ formattedValue }</div>
	);

}

export default memo(Die, (prevProps, nextProps) => {
	return prevProps.value === nextProps.value
		&& prevProps.rolling === nextProps.rolling
		&& prevProps.secret === nextProps.secret
		&& prevProps.color1 === nextProps.color1
		&& prevProps.color2 === nextProps.color2;
});
import {useCalculatedAge} from "../../hooks/utils/useCalculatedAge";

const Manager = ({data}) => {

	const calculatedAge = useCalculatedAge({age: data?.age});
	console.log('Manager', data);

	return (
		<div>Manager: { data.name} (Age: {calculatedAge})</div>
	);

}

export default Manager;
import {useTeamPositionAverage} from "../../../../hooks/game/useTeamPositionAverage";

const PositionAverage = ({players, position, modifier = 0, label}) => {

	console.log('PositionAverage', position);
	const average = useTeamPositionAverage({
		players,
		position,
		modifier,
	});

	return (
		<div>{label}: { average }</div>
	);
}

export default PositionAverage;
import { useGraphqlQuery } from "./useGraphqlQuery";
import { GET_FIXTURES_BY_TEAM } from "../graphql-queries/GET_FIXTURES_BY_TEAM";
import {useCurrentSeason} from "../context/GameSettingsContext";

export const useGetFixturesByTeam = ({teamId, season = null, fixtureTypeId = null, fixtureTypeSlug = null, }) => {

	const currentSeason = useCurrentSeason();

	const { loading, error, data } = useGraphqlQuery(GET_FIXTURES_BY_TEAM, {
		variables: {
			teamId: String(teamId),
			season: String(season !== null ? season : currentSeason),
		},
	});

	if(loading) {
		return { loading };
	}

	if(error) {
		return { error };
	}

	if(!data?.homeFixtures?.nodes || !data?.awayFixtures?.nodes) {
		return { data: null };
	}

	// Group fixtures by fixtureTypes
	const groupedHomeFixtures = groupFixturesByFixtureTypes(data.homeFixtures.nodes);
	const groupedAwayFixtures = groupFixturesByFixtureTypes(data.awayFixtures.nodes);

	// Merge and sort the fixtures based on the round property
	const mergedFixtures = mergeAndSortFixtures(
		groupedHomeFixtures,
		groupedAwayFixtures
	);

	// Filter
	if(fixtureTypeId) {
		const filteredFixtures = mergedFixtures.filter((fixture) => {
			return fixture.fixtureType.databaseId === fixtureTypeId;
		});
		return { data: filteredFixtures };
	}
	else if(fixtureTypeSlug) {
		const filteredFixtures = mergedFixtures.filter((fixture) => {
			return fixture.fixtureType.slug === fixtureTypeSlug;
		});
		return { data: filteredFixtures };
	}

	return { data: mergedFixtures };
};

// Helper function to group fixtures by fixtureTypes
const groupFixturesByFixtureTypes = (fixtures) => {
	return fixtures.reduce((groupedFixtures, fixture) => {
		const fixtureType = fixture.fixtureTypes?.nodes?.[0]; // add a check for fixtureTypes

		if (!fixtureType) {
			return groupedFixtures;
		}

		if (!groupedFixtures[fixtureType.databaseId]) {
			groupedFixtures[fixtureType.databaseId] = {
				fixtureType,
				fixtures: [],
			};
		}

		groupedFixtures[fixtureType.databaseId].fixtures.push(fixture);
		return groupedFixtures;
	}, {});
};


// Helper function to merge and sort fixtures based on the round property
const mergeAndSortFixtures = (groupedHomeFixtures, groupedAwayFixtures) => {
	const mergedFixtureGroups = {};

	// Merge home fixtures
	for(const homeFixtureGroup of Object.values(groupedHomeFixtures)) {
		const fixtureType = homeFixtureGroup.fixtureType;
		const fixtureGroupId = fixtureType.databaseId;

		if (!mergedFixtureGroups[fixtureGroupId]) {
			mergedFixtureGroups[fixtureGroupId] = {
				fixtureType,
				fixtures: [],
			};
		}

		mergedFixtureGroups[fixtureGroupId].fixtures.push(...homeFixtureGroup.fixtures);
	}

	// Merge away fixtures
	for(const awayFixtureGroup of Object.values(groupedAwayFixtures)) {
		const fixtureType = awayFixtureGroup.fixtureType;
		const fixtureGroupId = fixtureType.databaseId;

		if (!mergedFixtureGroups[fixtureGroupId]) {
			mergedFixtureGroups[fixtureGroupId] = {
				fixtureType,
				fixtures: [],
			};
		}

		mergedFixtureGroups[fixtureGroupId].fixtures.push(...awayFixtureGroup.fixtures);
	}

	// Sort fixtures by round
	for(const mergedFixtureGroup of Object.values(mergedFixtureGroups)) {
		mergedFixtureGroup.fixtures.sort(
			(a, b) => a.fixtureData.round - b.fixtureData.round
		);
	}

	return Object.values(mergedFixtureGroups);
};
import {memo, useEffect, useState} from "react";
import {useGetFilterOptionsDivisions} from "../../hooks/useGetFilterOptionsDivisions";

import {useTranslate} from "../../context/I18nContext";
import Select from 'react-select';

const Divisions = ({ onChange, defaultValue = null, multi = true }) => {
	const translate = useTranslate();
	const { getFilterOptions, data } = useGetFilterOptionsDivisions()

	const [options, setOptions] = useState([]);

	const handleMenuOpen = () => {
		if(!data) {
			getFilterOptions();
		}
	};

	useEffect(() => {
		if(data) {
			const divisions = data.map((division) => ({
				label: division.name,
				value: division.slug,
			}));
			setOptions(divisions);
		}
	}, [data]);

	return (
		<div className="divisions-filter">
			<label>
				{translate('filter_divisions_label')}
				<Select
					options={options}
					isMulti={multi}
					placeholder={translate('filter_divisions_placeholder')}
					onMenuOpen={handleMenuOpen}
					onChange={onChange}
					defaultValue={defaultValue}
					value={defaultValue}
				/>
			</label>
		</div>
	);
};

export default memo(Divisions, (prevProps, nextProps) => {
	return prevProps.defaultValue === nextProps.defaultValue;
});
import {memo} from "react";
import {useTranslation} from "../../../../context/I18nContext";

const Suspension = ({detail = null, iconOnly = false}) => {

	const suspended = useTranslation('player_data_suspended');

	console.log('Suspension', detail, iconOnly);

	return (
		<div className="suspension">
			{ iconOnly
				? <strong>Susp.</strong>
				: <><strong>{ suspended }</strong> { detail && `(${detail})` }</>
			}
		</div>
	);
};

export default memo(Suspension, (prevProps, nextProps) => {
	return prevProps.detail === nextProps.detail
		&& prevProps.iconOnly === nextProps.iconOnly;
});
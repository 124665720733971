import { gql } from '@apollo/client';

export const PLAYER_DATA_STATS = gql`
  fragment PlayerDataStats on Player_Playerdata_Stats {
	tech
	tac
	phys
	overall
	morale
	fitness
  }
`;
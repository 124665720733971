import {useGetFixtureByUri} from "../hooks/useGetFixtureByUri";
import {useTeamData} from "../hooks/game/useTeamData";

import Team from "../components/fixture/Team";
import MatchCentre from "../components/fixture/MatchCentre";
import ScoreBoard from "../components/fixture/ScoreBoard";

const FixturePage = () => {

	// Load fixture data
	const { loading, error, data } = useGetFixtureByUri();

	// Get the (modified) data for the teams. We collect all the teams' data into the same object
	const homeTeam = useTeamData(data);
	const awayTeam = useTeamData(data, false);

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No Fixture</p>

	console.log('FIXTURE', data);

	const { fixtureData, matchCentre } = { ...data };
	const { season } = { ...fixtureData };

	return (
		<div className="page team-page">
			<h1>FIXTURE PAGE</h1>
			<h2>Fixture Data:</h2>
			<ScoreBoard
				homeTeam={ homeTeam }
				awayTeam={ awayTeam }
			/>

			<div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>

				<div style={{flexBasis: "30%", order: 1}}>
					<Team
						data={ homeTeam }
						season={ season }
					/>
				</div>

				<div style={{flexBasis: "30%", order: 3}}>
					<Team
						data={ awayTeam }
						season={ season }
					/>
				</div>

				<div style={{flexBasis: "30%", order: 2}}>
					<MatchCentre
						matchCentreData={ matchCentre }
						fixtureData={ fixtureData }
					/>
				</div>

			</div>

			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li>Formations</li>
					<li>Warnings Player from other club</li>
					<li>Show stats? Stats are real time and not "historic". Solution: Archive option?</li>
				</ul>
			</div>
		</div>
	);
};

export default FixturePage;
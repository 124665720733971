import {useTranslation} from "../../context/I18nContext";

const SecretOption = ({ onChange, checked = true, disabled = false, color1 = null, color2 = null, }) => {

	const label = useTranslation('dice_secret_roll');

	return (
		<div style={{marginBottom: "1em"}}>
			<label>
				<input
					type="checkbox"
					defaultChecked={checked}
					disabled={ disabled }
					onChange={(e) => {
						onChange(e.target.checked);
					}}
				/>
				<span style={{background: color1, color: color2}}>{label}</span>
			</label>
		</div>
	);

}

export default SecretOption;
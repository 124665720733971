import { gql } from '@apollo/client';
import {PLAYER_DATA_STATS} from "./PLAYER_DATA_STATS";
import {PLAYER_DATA_AVAILABILITY} from "./PLAYER_DATA_AVAILABILITY";

export const FIXTURE_LINEUP_PLAYER = gql`
fragment FixtureLineupPlayer on Player {
  ... on Player {
  	id
	slug
	title
	uri
	playerData {
	  age
	  shirtNumber
	  nationality
	  international
	  u21
	  stats {
		...PlayerDataStats
	  }
	  availability {
	  	...PlayerDataAvailability
	  }
	}
  }
}
${PLAYER_DATA_STATS}
${PLAYER_DATA_AVAILABILITY}
`;
import {memo} from "react";
import {NavLink} from "react-router-dom";

const Referee = ({data}) => {

	console.log('Referee', data);

	return (
		<li key={data.slug}>
			<NavLink to={data.uri}>{data.title}</NavLink>
		</li>
	);
}


export default memo(Referee, (prevProps, nextProps) => {
	return prevProps.data.uri === nextProps.data.uri;
});
import {useGetTeamByUri} from "../hooks/useGetTeamByUri";

import Players from "../components/team/players/Players";
import Fixtures from "../components/team/fixtures/Fixtures";
import LeagueTable from "../components/team/league-table/LeagueTable";
import LeagueTableProgress from "../components/team/league-table/LeagueTableProgress";
import Manager from "../components/team/Manager";
import Stadium from "../components/team/Stadium";
import BaseTactics from "../components/team/BaseTactics";
import Divisions from "../components/team/Divisions";

const TeamPage = () => {

	const { loading, error, data } = useGetTeamByUri();


	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No Team</p>
	console.log('TeamPage', data);

	const { databaseId, teamData, divisions } = { ...data };

	console.log('divisions',divisions);


	return (
		<div className="page team-page">
			<h1>TEAM PAGE</h1>
			<h2>{ data.title}</h2>
			<h3>Misc. data</h3>
			<Manager data={teamData.manager } />
			<Stadium data={teamData.stadium} />
			<BaseTactics data={teamData.tactics} />
			{ divisions?.nodes && <Divisions divisions={divisions.nodes} /> }

			<Players
				teamId={ databaseId }
			/>
			{
				Array.isArray(divisions?.nodes) && divisions?.nodes.length > 0
					? (
						<>
							<LeagueTable
								division={ divisions?.nodes[0].databaseId }
								teamId={ databaseId }
							/>
							<LeagueTableProgress
								division={ divisions?.nodes[0].databaseId }
								teamId={ databaseId }
							/>
						</>
					)
					: null
			}
			<Fixtures
				teamId={ databaseId }
			/>
			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li>Location</li>
					<li>Player performances</li>
					<li>Kits</li>
					<li>Dice</li>
					<li>Staff</li>
					<li>Economy</li>
					<li>Transfers</li>
					<li>Former players</li>
				</ul>
			</div>
		</div>
	);
};

export default TeamPage;
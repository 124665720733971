import { gql } from '@apollo/client';

export const GET_INITIAL_DATA = gql`
query getInitialData {
  generalSettings {
  	title
    dateFormat
    timeFormat
  }
  readingSettings {
    postsPerPage
  }
  acfOptionsGameSettings {
    gameSettings {
      currentSeason
      seasonsAvailable
    }
  }
  acfOptionsTranslations {
    i18n {
      translationStrings {
        key
        value
      }
    }
  }
  weather {
    key
    value
  }
  matchEventTypes {
    key
    value
  }
  playingStatuses {
    key
    value
  }
  countries {
    key
    value
  }
}
`;
import {gql} from "@apollo/client";

export const GET_FILTER_OPTIONS_POSITIONS = gql(`
query getFilterOptionsPositions {
  positions(first: 9999) {
    nodes {
      slug
      name
    }
  }
}
`);
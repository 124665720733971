import Fixture from "./Fixture";

const FixturesByType = ({fixtureType, fixtures}) => {

	console.log('FixturesByType', fixtureType, fixtures);

	return (
		<div>
			<h4>{fixtureType.name}</h4>

			<div className="rounds" style={{display: "flex", flexDirection:"column-reverse"}}>
				{
					fixtures.map(fixture => {
						return (
							<Fixture
								key={fixture.slug}
								data={ fixture }
							/>
						)
					})
				}
			</div>

		</div>
	);
}

export default FixturesByType;
import {NavLink} from "react-router-dom";
import {useCalculatedAge} from "../../../hooks/utils/useCalculatedAge";
import {useCountries} from "../../../context/CountriesContext";

const Player = ({ data }) => {

	const countries = useCountries();
	const calculatedAge = useCalculatedAge({age:data?.playerData?.age});
	const { title, uri, playerData } = { ...data }

	return (
		<li>
			{playerData.shirtNumber}&nbsp;
			<NavLink to={uri}> {title}</NavLink>&nbsp;
			Age: { calculatedAge }&nbsp;
			Nationality: { playerData.nationality.map(countryCode => `${countries(countryCode)} `) }&nbsp;
			Positions: { playerData.positions.map(position => `${position.positionData.abbreviation} `) }&nbsp;
			Sides: { playerData.sides.map(side => `${side.positionData.abbreviation} `) }&nbsp;
		</li>
	);
}

export default Player;
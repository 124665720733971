import {memo} from "react";
import {useTranslation} from "../../../../context/I18nContext";

const Injury = ({detail = null, iconOnly = false}) => {

	const injured = useTranslation('player_data_injured');

	console.log('Injury', detail, iconOnly);

	return (
		<div className="injury">
			{ iconOnly
				? <strong>Inj.</strong>
				: <><strong>{ injured }</strong> { detail && `(${detail})` }</>
			}
		</div>
	);
};

export default memo(Injury, (prevProps, nextProps) => {
	return prevProps.detail === nextProps.detail
		&& prevProps.iconOnly === nextProps.iconOnly;
});
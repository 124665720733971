import {useTranslation} from "../../../context/I18nContext";

const NoPlayer = () => {

	const noPlayer = useTranslation('match_centre_no_player_selected');
	console.log('NoPlayer');

	return (
		<div><em>{noPlayer}</em></div>
	);
}

export default NoPlayer;
import {useGraphqlQuery} from "./useGraphqlQuery";
import {useCurrentSeason} from "../context/GameSettingsContext";
import {GET_LEAGUE_TABLE_BY_DIVISION} from "../graphql-queries/GET_LEAGUE_TABLE_BY_DIVISION";

export const useGetLeagueTableByDivision = ({division, season = null, teamId = null, numTeamsToShow = 5}) => {

	const currentSeason = useCurrentSeason();

	const { loading, error, data } = useGraphqlQuery(GET_LEAGUE_TABLE_BY_DIVISION, {
		variables: {
			division: division,
			season: season !== null ? season : currentSeason, // Note. This time we need season to be an integer
		},
	});

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}
	if(!data?.leagueTable) {
		return { data: null };
	}

	let leagueTable = data.leagueTable;
	if(teamId !== null) {
		leagueTable = getTableSnippet(leagueTable, teamId, numTeamsToShow);
	}

	return { data: leagueTable, lastUpdated: leagueTable?.[0]?.lastUpdated ? leagueTable[0].lastUpdated : null };
};

// Filter the table, so we get "numberOfTeamsToShow" teams. We get the teams above and below the teamId
const getTableSnippet = (teamsArray, teamId, numTeamsToShow = 5) => {
	const teamIndex = teamsArray.findIndex(team => team.teamId === teamId);
	const teamsToShow = [];
	let numberOfTeamsToShow = ( numTeamsToShow % 2 === 0 ? numTeamsToShow + 1 : numTeamsToShow );
	let numTeamsToShowOnEachSide = Math.floor((numberOfTeamsToShow - 1) / 2);
	let numTeamsToAddBefore = numTeamsToShowOnEachSide;
	let numTeamsToAddAfter = numTeamsToShowOnEachSide;
	if(teamIndex < numTeamsToShowOnEachSide) {
		numTeamsToAddBefore = teamIndex;
	}
	else if(teamIndex >= teamsArray.length - numTeamsToShowOnEachSide) {
		numTeamsToAddAfter = teamsArray.length - 1 - teamIndex;
		numTeamsToAddBefore = numberOfTeamsToShow - numTeamsToAddAfter - 1;
	}
	const startIndex = Math.max(teamIndex - numTeamsToAddBefore, 0);
	const endIndex = Math.min(startIndex + numberOfTeamsToShow, teamsArray.length);
	for(let i = startIndex; i < endIndex; i++) {
		teamsToShow.push(teamsArray[i]);
	}
	if(teamsToShow.length < numberOfTeamsToShow) {
		const numToAdd = numberOfTeamsToShow - teamsToShow.length;
		if(numTeamsToAddBefore > 0) {
			const maxIndex = Math.min(teamIndex, numTeamsToAddBefore);
			for(let i = 1; i <= maxIndex; i++) {
				teamsToShow.unshift(teamsArray[teamIndex - i]);
			}
			numTeamsToAddBefore -= maxIndex;
		}
		for(let i = 1; i <= numTeamsToAddBefore; i++) {
			const beforeIndex = teamIndex - i;
			if(beforeIndex >= 0 && !teamsToShow.includes(teamsArray[beforeIndex])) {
				teamsToShow.unshift(teamsArray[beforeIndex]);
			}
		}
		for(let i = 1; i <= numToAdd - numTeamsToAddBefore; i++) {
			const afterIndex = teamIndex + i;
			if(afterIndex < teamsArray.length && !teamsToShow.includes(teamsArray[afterIndex])) {
				teamsToShow.push(teamsArray[afterIndex]);
			}
		}
	}
	return teamsToShow;
};
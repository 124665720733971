import {useGetLeagueTableProgress} from "../../../hooks/useGetLeagueTableProgress";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const LeagueTableProgress = ({ division, teamId, season = null }) => {

	const { loading, error, data } = useGetLeagueTableProgress({
		division,
		season: ( season ? season : null ),
		teamId,
	});

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No League Table Progress</p>

	console.log('LeagueTableProgress', division, season, {data});

	const { progress, form } = { ...data};

	const chartData = progress.length > 0 ? progress.map(progressRound => {
		return {
			round: progressRound.round,
			name: `Round ${progressRound.round}`,
			position: progressRound.position,
		}
	}) : null;

	return (
		<>
			<h3>League Table progress</h3>
			{
				progress.length > 0
				? (
					<>
						{
							chartData ? (
								<>
									<LineChart
										width={600}
										height={300}
										data={chartData}
										margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
									>
										<Line type="monotone" dataKey="position" stroke="#8884d8" strokeWidth={3} />
										<CartesianGrid stroke="#f1f1f1" strokeWidth={0} />
										<XAxis
											dataKey="round"
											interval={0}
											domain={[1, chartData.length]}
											tickSize={1}
											tickCount={chartData.length}
											label={{ value: 'Rounds', position: 'insideBottomRight', offset: -20 }}
											tickMargin={10}
										/>
										<YAxis
											domain={[1, 10]}
											interval={0}
											tickSize={1}
											tickCount={10}
											reversed={true}
											label={{ value: 'Positions', angle: -90, position: 'insideLeft', offset: 20 }}
											tickMargin={10}
										/>
										<Tooltip
											formatter={(value) => [`Position: ${value}`, null]}
											labelFormatter={(value) => `Round ${value}`}
										/>
									</LineChart>
								</>

							) : null
						}
					</>
				)
				: <p>No League Table progress</p>
			}
			<h3>League Table form guide (last -> first)</h3>
			{
				form.length > 0
					? form
					: <p>No League Table form guide</p>
			}
		</>
	);
};

export default LeagueTableProgress;
import {useGraphqlQuery} from "./useGraphqlQuery";
import {GET_INITIAL_DATA} from "../graphql-queries/GET_INITIAL_DATA";

export const useGetInitialData = () => {
	const { loading, error, data } = useGraphqlQuery(GET_INITIAL_DATA);

	if(loading) {
		return { loading };
	}
	if(error) {
		return { error };
	}
	return { data };
};
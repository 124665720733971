import {useGetRefereeByUri} from "../hooks/useGetRefereeByUri";

const RefereePage = () => {

	const { loading, error, data } = useGetRefereeByUri();

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error : {error.message}</p>;
	if(!data) return <p>No Referee</p>
	console.log('REFEREE', data);

	return (
		<div className="page referee-page">
			<h1>REFEREE PAGE</h1>
			{ data.title}
			<div style={{color:"tomato"}}>
				<h3>TODO</h3>
				<ul>
					<li>Icons?</li>
					<li>Fixture history?</li>
					<li>Card stats?</li>
				</ul>
			</div>
		</div>
	);
};

export default RefereePage;
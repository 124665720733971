import {useEffect, useState} from "react";
import {useLocalStorage} from "../../hooks/utils/useLocalStorage";
import {useDiceRoll} from "../../hooks/services/useDiceRoll";
import Die from "./Die";
import Log from "./Log";
import RollButton from "./RollButton";
import SecretOption from "./SecretOption";

const TeamDice = ({ teamId, color1 = null, color2 = null }) => {

	const [rolls, saveRoll] = useDiceRoll({teamId});
	const [isRolling, setIsRolling] = useState(false);
	const [isSecret, setIsSecret] = useLocalStorage(`DiceSecretRoll${teamId}`, true);

	useEffect(() => {
		let timeoutId;
		if(isRolling) {
			timeoutId = setTimeout(() => {
				setIsRolling(false);
			}, 1000);
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [isRolling]);

	const rollDice = () => {
		const value = Math.floor(Math.random() * 100) + 1;
		saveRoll({
			value: value,
			timestamp: new Date().getTime(),
			secret: isSecret,
		});
		setIsRolling(true);
	};

	console.log('TeamDice', teamId, rolls, isRolling, color1, color2, isSecret);

	const tensDigit = rolls && rolls[0] && Math.floor(rolls[0].value / 10) * 10;
	const onesDigit = rolls && rolls[0] && rolls[0].value % 10;

	return (
		<div className="team-dice">
			<h4>Team Dice</h4>
			<Die
				key={`face-10-${rolls && rolls[0] && rolls[0].timestamp}`}
				digit={10}
				value={tensDigit === 100 ? 0 : tensDigit}
				secret={rolls && rolls[0] && rolls[0].secret}
				rolling={isRolling}
				color1={color1}
				color2={color2}
			/>
			<Die
				key={`face-1-${rolls && rolls[0] && rolls[0].timestamp}`}
				digit={1}
				value={onesDigit}
				secret={rolls && rolls[0] && rolls[0].secret}
				rolling={isRolling}
				color1={color1}
				color2={color2}
			/>
			<RollButton
				onClick={ rollDice }
				disabled={ isRolling }
				color1={color1}
				color2={color2}
			/>
			<SecretOption
				onChange={(checked) => {
					console.log('ssa',checked);
					setIsSecret(checked);
				}}
				checked={isSecret}
				disabled={ isRolling }
				color1={color1}
				color2={color2}
			/>
			<ul>
				{
					rolls.length > 0
						? rolls.map((roll, index) => {
							return <Log
								key={roll.timestamp}
								data={roll}
								rolling={ isRolling && index === 0 }
							/>
						})
						: null
				}
			</ul>
		</div>
	);
};

export default TeamDice;

import {memo} from "react";
import {useTranslation} from "../../../../context/I18nContext";
import Stat from "./Stat";

const Morale = ({ value }) => {
	console.log('Morale', value);
	const label = useTranslation('morale');

	const getThresholdClass = () => {
		if(value > 20) {
			return "morale-level-1";
		}
		else if(value > 10) {
			return "morale-level-2";
		}
		else if(value >= 0) {
			return "morale-level-3";
		}
		else if(value > -10) {
			return "morale-level-4";
		}
		return "morale-level-5";
	};

	return (
		<Stat
			value={ value }
			label={ label }
			prefix={ value > 0 ? '+' : null }
			className={ getThresholdClass() }
		/>
	);
}

export default memo(Morale, (prevProps, nextProps) => {
	return prevProps.value === nextProps.value;
});
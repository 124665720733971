import {useTranslation} from "../../context/I18nContext";

const ScoreBoard = ({homeTeam, awayTeam}) => {

	console.log('ScoreBoard', homeTeam, awayTeam);
	const noScore = useTranslation('fixture_no_score');
	const hasScore = (homeTeam.score || homeTeam.score === 0) && (awayTeam.score || awayTeam.score === 0)

	return (
		<div>
			<h3>{ homeTeam.title } { hasScore ? `${homeTeam.score} - ${awayTeam.score}` : '-' } { awayTeam.title }</h3>
			{ !hasScore ? <em>{noScore}</em> : null }
		</div>
	);

}
export default ScoreBoard;
import {gql} from "@apollo/client";

export const GET_FILTER_OPTIONS_TEAMS = gql(`
query getFilterOptionsTeams {
  teams(where: {orderby: {field: TITLE, order: ASC}}, first: 9999) {
    nodes {
      databaseId
      title
    }
  }
}
`);